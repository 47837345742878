import logo from "./logo.svg";
// import "./App.css";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SignIn from "./views/Auth/SignIn";
import SignUp from "./views/Auth/SignUp";
import CreateSolutionResultPage from "./layouts/CreateSolutionResultPage.js";
import ModifySolutionPage from "./layouts/ModifySolutionPage";
import ModifySolutionPageResult from "./layouts/ModifySolutionPageResult";
import SelectPassagePage from "./layouts/SelectPassagePage.js";
import CreateQuestionPage from "./layouts/CreateQuestionPage.js";
import ShowPassageListPage from "./layouts/ShowPassageListPage.js";
import ProblemBankPage from "./layouts/ProblemBankPage.js";
import ProblemBankResultPage from "./layouts/ProblemBankResultPage.js";
import WritePassagePage from "./layouts/WritePassagePage.js";
import CreatePassageAIPage from "./layouts/CreatePassageAIPage.js";
import CreateLiteraturePage from "./layouts/CreateLiteraturePage.js";
import PoemExtractLiteraturePage from "./layouts/PoemExtractLiteraturePage.js";
import NovelExtractLiteraturePage from "./layouts/NovelExtractLiteraturePage.js";
import ErrorPage from "./layouts/ErrorPage.js";
import CreateNovelSupPage from "./layouts/CreateNovelSupPage.js";
import Pages from "./layouts/Auth.js";
import Dashboard from "./layouts/Admin.js";
import React, { useEffect, useState } from "react";
import { Spinner, Center } from "@chakra-ui/react"; // Import Chakra UI Spinner and Center

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAndRefreshToken = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPath = window.location.pathname;
  
        if (currentPath === '/signup') {
          setLoading(false);
          return;
        }
  
        if (!token) {
          navigate('/signin');
          return;
        }
  
        try {
          // Verify token
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/verify/`,
            { token }
          );
          if (response.data.code === "token_not_valid") {
            throw new Error("Token expired");
          }
        } catch (verifyError) {
          // Attempt token refresh
          const refreshToken = localStorage.getItem("refresh");
          if (!refreshToken) {
            navigate('/signin');
            return;
          }
  
          try {
            const refreshResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/refresh/`,
              { refresh: refreshToken }
            );
            const newToken = refreshResponse.data.access;
            localStorage.setItem("token", newToken);
          } catch (refreshError) {
            console.error("Error refreshing token", refreshError);
            navigate('/signin');
            return;
          }
        }
      } catch (error) {
        console.error("Error verifying or refreshing token", error);
        navigate('/signin');
      } finally {
        setLoading(false);
      }
    };
  
    verifyAndRefreshToken();
  }, [navigate]);
  
  

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner
          thickness="4px"
          color="blue.500"
          width="100px"
          height="100px"
        />
      </Center>
    );
  }

  return (
    <div>
      {window.localStorage.getItem("token") ? (
        <Routes>
          <Route path="/" element={<ProblemBankPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="create-solution" element={<ProblemBankPage />} />
          {/* <Route path="select-passage/" element={<SelectPassagePage />} />         */}
          <Route
            path="create-solution-result/"
            element={<CreateSolutionResultPage />}
          />
          <Route path="modify-problem/" element={<ModifySolutionPage />} />
          <Route
            path="modify-problem-result/"
            element={<ModifySolutionPageResult />}
          />
          <Route path="problem-bank/" element={<ProblemBankPage />} />
          <Route
            path="problem-bank-result/"
            element={<ProblemBankResultPage />}
          />
          <Route path="passage-list/" element={<ShowPassageListPage />} />
          <Route path="create-question/" element={<CreateQuestionPage />} />
          <Route path="write-passage/" element={<WritePassagePage />} />
          <Route
            path="passage-literature-create/"
            element={<CreateLiteraturePage />}
          />
          <Route
            path="/poem-passage-literature-extract/"
            element={<PoemExtractLiteraturePage />}
          />
          <Route
            path="/novel-passage-literature-extract/"
            element={<NovelExtractLiteraturePage />}
          />
          <Route path="/novel-sup-create/" element={<CreateNovelSupPage />} />
          <Route path="passage-ai-create/" element={<CreatePassageAIPage />} />
          <Route
            path="passage-bmoon-create/"
            element={<CreatePassageAIPage />}
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="write-passage/" element={<MathExamPaperEditorPage />} /> */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
