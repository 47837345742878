import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Grid,
  HStack,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import LiteraryWorkModal from "./components/LiteraryWorkModal";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import
import { useNavigate, useLocation } from "react-router-dom";
import NovelPartModal from "./components/NovelPartModal";

export default function NovelExtractLiteratureDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedWork, setSelectedWork] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [novelFullText, setNovelFullText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [retrievedTexts, setRetrievedTexts] = useState([]);
  const [orgRetrievedTexts, setOrgRetrievedTexts] = useState([]);
  const [namespace, setNamespace] = useState(false);
  const [selectedParts, setselectedParts] = useState([]);
  const [currentFindPart, setCurrentFindPart] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if work and category are passed in from the previous page
    if (location.state) {
      const { work, category } = location.state;

      if (!work || !category) {
        // Redirect to a specific error page with the error message
        navigate("/error", {
          state: {
            errorMessage:
              "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
          },
        });
      } else {
        // Set the selected work if both work and category exist
        setSelectedWork(work);
        setSelectedCategory(category);
        setNovelFullText(work.contents);
      }
    } else {
      // Redirect to error if location.state is not present
      navigate("/error", {
        state: {
          errorMessage:
            "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
        },
      });
    }
  }, [location.state, navigate]);

  const handleRetrieveContent = () => {
    if (keyword.trim() === "") return;

    setIsRetrieving(true); // Disable the button while processing
    // API call for poetry
    apiClient
      .post(
        "/problem_generator/retrieve_similar_novels/", // 상대 경로만 사용
        {
          text: keyword,
          selected_text: selectedWork.title,
          author: selectedWork.author,
        }
      )
      .then((response) => {
        const processedTexts = response.data.retrieved_texts
          .map((item) => {
            const match = item.text.match(/^\d+\.\s/); // Extract the leading number
            const numberIndex = match ? parseInt(match[0]) : null; // Get the number or null if no match
            const textWithoutNumber = match
              ? item.text.replace(match[0], "").trim() // Remove only the matched number and trim extra spaces
              : item.text; // If no match, keep the text as-is
            return {
              ...item,
              text: textWithoutNumber, // Update the text without the exact number match
              number_index: numberIndex, // Keep the extracted number in the object
              counter: 0, // Initialize the counter
            };
          })
          .sort((a, b) => a.number_index - b.number_index);
        const deepCopy = JSON.parse(JSON.stringify(processedTexts)); // Create a deep copy

        setOrgRetrievedTexts(deepCopy);
        setRetrievedTexts(processedTexts);
        setNamespace(response.data.namespace);
      })
      .catch((error) => {
        console.error(
          "Error fetching similar poems:",
          error.response?.data || error.message
        );
      })
      .finally(() => {
        setIsRetrieving(false); // Re-enable the button when request is completed
      });
  };

  const handleKeywordClick = (newKeyword) => {
    setKeyword((prev) =>
      prev.trim() === "" ? newKeyword : `${prev}, ${newKeyword}`
    ); // Append with a comma if input is not empty
  };

  const handlePartSelection = (index) => {
    if (selectedParts.includes(index)) {
      setselectedParts(selectedParts.filter((i) => i !== index)); // Deselect if clicked again
    } else {
      if (selectedParts.length < 2) {
        setselectedParts([...selectedParts, index]); // Add index if less than 2
      } else {
        setselectedParts([selectedParts[1], index]); // Replace the first index
      }
    }
  };

  const handleFindPartClick = (part) => {
    setCurrentFindPart(part);
    setIsModalOpen(true);
  };

  const categoryMap = {
    modern_novel: "문학 작품 - 현대 소설",
    classic_novel: "문학 작품 - 고전 소설",
  };

  const toggleEditMode = (index) => {
    setEditMode((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleTextChange = (index, newText) => {
    const updatedTexts = [...retrievedTexts];
    updatedTexts[index].text = newText; // Update the text of the specified index
    setRetrievedTexts(updatedTexts);
  };

  // Highlight the selected part in the novel text
  const locatePartInText = (text, part, type) => {
    const normalize = (str) => str.replace(/[\r\n\s]+/g, "").toLowerCase();
    const normalizedText = normalize(text); // Cleaned version of the full text
    //const normalizedPart = normalize(part.text.replace(/^\d+\.\s*/, "")); // Cleaned version of the part to match
    const normalizedPart = normalize(part);

    const startIndex = normalizedText.indexOf(normalizedPart);
    if (startIndex === -1) {
      console.log("No matches found after normalization.");
      return text;
    }

    let matchLength = 0;
    let originalStartIndex = 0;

    for (let i = 0; i < text.length; i++) {
      if (!/\s/.test(text[i]) && text[i] !== "\r" && text[i] !== "\n") {
        if (matchLength === startIndex) {
          originalStartIndex = i;
          break;
        }
        matchLength++;
      }
    }

    let matchEndIndex = originalStartIndex;
    while (
      matchLength < startIndex + normalizedPart.length &&
      matchEndIndex < text.length
    ) {
      if (
        !/\s/.test(text[matchEndIndex]) &&
        text[matchEndIndex] !== "\r" &&
        text[matchEndIndex] !== "\n"
      ) {
        matchLength++;
      }
      matchEndIndex++;
    }

    if (type == "surrounding") {
      return {
        beforeText: text.slice(0, originalStartIndex), // Text before the part
        afterText: text.slice(matchEndIndex), // Text after the part
      };
    }
    if (type == "add") {
      return {
        addText: text.slice(originalStartIndex, matchEndIndex),
      };
    }
  };

  const handleAddLines = (index, position) => {
    const updatedTexts = [...retrievedTexts];
    const currentText = updatedTexts[index];
    const linesToAdd = currentText.counter || 0; // Get the counter value

    if (linesToAdd > 0) {
      const fullText = novelFullText; // Full text of the novel
      const partText = currentText.text;

      // Locate the part in the full text
      const locationText = locatePartInText(fullText, partText, "surrounding");
      if (!locationText) return;

      // Extract text before or after the current part
      const surroundingText =
        position === "top" ? locationText.beforeText : locationText.afterText;

      // Split sentences while handling edge cases like ." or !”
      const sentences = surroundingText
        .split(/(?<=[.!?])(?=\s|["])/)
        .filter((sentence) => sentence.trim().length > 0);
      const appendedText =
        position === "top"
          ? sentences.slice(-linesToAdd).join("") + currentText.text // Prepend for "top"
          : currentText.text + sentences.slice(0, linesToAdd).join(""); // Append for "bottom"

      const newText = locatePartInText(fullText, appendedText, "add").addText;
      if (!newText) {
        console.log("Selected lines not found in the full text.");
        return;
      }
      // Update the text by appending or prepending the cropped text
      updatedTexts[index] = {
        ...currentText,
        text: newText,
        counter: 0, // Reset the counter
      };

      // Update the state
      setRetrievedTexts(updatedTexts);
    }
  };

  const handleIncrement = (index) => {
    const updatedTexts = [...retrievedTexts];
    const currentCounter = updatedTexts[index].counter || 0;

    if (currentCounter < 10) {
      updatedTexts[index] = {
        ...updatedTexts[index],
        counter: currentCounter + 1, // Increment the counter
      };
      setRetrievedTexts(updatedTexts);
    }
  };

  const handleDecrement = (index) => {
    const updatedTexts = [...retrievedTexts];
    const currentCounter = updatedTexts[index].counter || 0;

    if (currentCounter > 0) {
      updatedTexts[index] = {
        ...updatedTexts[index],
        counter: currentCounter - 1, // Decrement the counter
      };
      setRetrievedTexts(updatedTexts);
    }
  };

  const revertToOriginal = (index) => {
    setRetrievedTexts((prev) =>
      prev.map(
        (item, i) => (i === index ? { ...orgRetrievedTexts[index] } : item) // Revert the specific index
      )
    );
  };

  const handleCompleteSelection = async () => {
    setIsGenerating(true);
    // Step 1: Reorder selectedParts based on the number at the beginning of the text
    if (selectedParts.length === 2) {
      const [firstIndex, secondIndex] = selectedParts.sort(); // Sort indices
      const firstPart =
        retrievedTexts[firstIndex].number_index +
        ". " +
        retrievedTexts[firstIndex].text;
      const secondPart =
        retrievedTexts[secondIndex].number_index +
        ". " +
        retrievedTexts[secondIndex].text;
      const initialTitle = selectedWork.title;
      const initialAuthor = selectedWork.author;
      const passageId = selectedWork.id;
      const option = categoryMap[selectedCategory]; // Use the category mapping

      try {
        // Step 3: Make a POST request to the backend using apiClient
        const response = await apiClient.post(
          "/problem_generator/generate_sup_keyword_novels/", // baseURL이 설정된 경우 상대 경로로 사용
          {
            title: initialTitle,
            author: initialAuthor,
            first: firstPart,
            second: secondPart,
            namespace: namespace,
            category: option,
          }
        );
        // Step 4: Extract the passage and keywords from the response
        const { passage, id, keywords } = response.data;

        // Function to clean keywords only if necessary
        const cleanResponse = (orgKeywords) => {
          // Check if the text starts with "{" and ends with "}"
          if (orgKeywords.startsWith("{") && orgKeywords.endsWith("}")) {
            return orgKeywords; // Return as-is if already in the correct format
          }

          // Otherwise, clean the text by removing everything outside the braces
          const startIndex = orgKeywords.indexOf("{");
          const endIndex = orgKeywords.lastIndexOf("}") + 1;

          // Check if both braces exist in the keywords string
          if (startIndex !== -1 && endIndex !== -1) {
            return orgKeywords.slice(startIndex, endIndex); // Return only the content inside the braces
          }

          return orgKeywords; // Return original string if no valid braces found
        };

        const cleanedKeywords = cleanResponse(keywords);
        // Since 'keywords' comes as a string, parse it to extract the categories
        let keywordsObjects = [];

        try {
          // Attempt to parse the keywords string as JSON
          const keywordsParsed = JSON.parse(cleanedKeywords);

          keywordsObjects = Object.entries(keywordsParsed).map(
            ([key, text]) => ({
              key: key.trim(),
              text: `${key.trim()}: ${text.trim()}`,
            })
          );
        } catch (e) {
          console.error("Error parsing keywords:", e);
          alert("키워드 데이터를 파싱하는 도중 문제가 발생했습니다.");
          setIsGenerating(false);
          return;
        }
        setIsGenerating(false);

        // Log the keywordsObjects to debug

        // Step 5: Navigate to the next page with the received data

        const title = `${selectedWork.author}, 「${selectedWork.title}」`;
        navigate("/novel-sup-create/", {
          state: {
            title,
            passage,
            keywords: keywordsObjects,
            passage_id: id,
            option,
          },
        });
      } catch (error) {
        console.error("Error generating supplementary keywords:", error);
        setIsGenerating(false);
        alert(
          "보충 정보를 생성하는 도중 문제가 발생했습니다. 다시 시도해 주세요."
        );
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box>
          <Heading size="lg" color="teal.600">
            키워드 입력
          </Heading>
          <Text color="gray.600" mt={2}>
            지문 발췌를 위한 키워드 문구를 입력해주세요
          </Text>
        </Box>
        {selectedWork && (
          <HStack spacing={0} w="100%" alignItems="stretch">
            {/* Selected Work Section */}
            <HStack flex="6">
              <Box px={6} w="100%">
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  boxShadow="md"
                  bg="gray.50"
                  _hover={{
                    boxShadow: "lg",
                    bg: "gray.50",
                  }}
                  transition="all 0.3s ease"
                >
                  <HStack justify="space-between" align="center">
                    <VStack align="start" spacing={2}>
                      <Text fontSize="lg" fontWeight="bold" color="teal.700">
                        {selectedWork.author}, 「{selectedWork.title}」
                      </Text>
                      <Text color="gray.500">
                        {selectedWork.description || "작품 설명(EBS 연계 등)"}
                      </Text>
                    </VStack>

                    <HStack>
                      <Button
                        borderRadius="full"
                        border="1px solid"
                        borderColor="gray.300"
                        bg="gray.50"
                        color="gray.500"
                        _hover={{
                          bg: "gray.100",
                        }}
                        onClick={onOpen}
                      >
                        작품 보기
                      </Button>
                      <Button
                        borderRadius="full"
                        colorScheme="red"
                        onClick={() =>
                          navigate("/passage-literature-create/", {
                            state: { initialCategory: selectedCategory },
                          })
                        }
                      >
                        작품 재선택
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
              </Box>
            </HStack>

            {/* Keyword Input Section */}
            <VStack
              flex="4"
              w="100%"
              h="100%"
              spacing={2}
              p={0}
              justify="center" // Vertical centering
              align="center"
            >
              <HStack spacing={1} w="100%" h="100%" justify="center">
                {selectedWork.suggested_keywords
                  .split(",")
                  .map((keyword, index) => (
                    <Button
                      key={index}
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      onClick={() => handleKeywordClick(keyword.trim())} // Pass trimmed keyword
                    >
                      {keyword.trim()}
                    </Button>
                  ))}
              </HStack>
              <HStack spacing={2} w="100%" h="100%" justify="center">
                <Input
                  placeholder="키워드 문구 입력"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  textAlign="center"
                  flex="1"
                />
                <Button
                  borderRadius="full"
                  colorScheme="teal"
                  onClick={handleRetrieveContent}
                  isDisabled={isRetrieving}
                  isLoading={isRetrieving}
                  flex="1"
                >
                  지문 발췌
                </Button>
              </HStack>
            </VStack>
          </HStack>
        )}
        {retrievedTexts.length > 0 && !isRetrieving && (
          <>
            <Box>
              <Heading size="lg" color="teal.600">
                지문 선택
              </Heading>
              <Text color="gray.600" mt={2}>
                보기 생성을 위한 지문 2개를 선택해주세요
              </Text>
            </Box>

            {/* Display 10 Retrieved Texts in a Grid */}
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={4}
            >
              {retrievedTexts.map((part, index) => (
                <VStack key={index}>
                  <Box
                    key={index}
                    bg="white"
                    borderWidth="1px"
                    borderRadius="lg"
                    p={2}
                    boxShadow="md"
                    onClick={() => {
                      if (!editMode[index]) {
                        handlePartSelection(index);
                      }
                    }}
                    cursor={editMode[index] ? "not-allowed" : "pointer"}
                    border={
                      selectedParts.includes(index)
                        ? "2px solid teal"
                        : "1px solid gray"
                    }
                    _hover={{
                      border: !editMode[index] ? "2px solid gray" : undefined, // Disable hover effect in edit mode
                    }}
                    transition="all 0.3s ease"
                    height="300px" // Adjust the height as needed
                    overflowY="auto" // Makes the content scrollable vertically
                    width="100%"
                    boxSizing="border-box"
                  >
                    {editMode[index] ? (
                      <Textarea
                        value={part.text}
                        onChange={(e) =>
                          handleTextChange(index, e.target.value)
                        }
                        p={0}
                        lineHeight="tall"
                        color="gray.600"
                        whiteSpace="pre-wrap"
                        height="100%"
                        width="100%"
                        border="none"
                        boxShadow="none"
                        focusBorderColor="transparent"
                        _focusVisible={{
                          outline: "none",
                        }}
                        resize="none"
                      />
                    ) : (
                      <Text
                        color="gray.600"
                        whiteSpace="pre-wrap"
                        p={0}
                        lineHeight="tall"
                      >
                        {part.text}
                      </Text>
                    )}
                  </Box>
                  <HStack>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => revertToOriginal(index)}
                      fontSize="2xl"
                      p={0}
                      pb={1}
                    >
                      &#10226;
                    </Button>
                    <Button
                      size="sm"
                      colorScheme={!editMode[index] ? "teal" : "red"}
                      onClick={() => toggleEditMode(index)}
                    >
                      {editMode[index] ? "완료" : "편집"}
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => handleFindPartClick(part)}
                      isDisabled={editMode[index]}
                    >
                      본문에서 확인
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => handleDecrement(index)}
                      isDisabled={editMode[index] || part.counter <= 0}
                    >
                      -
                    </Button>
                    <Text>{part.counter || 0}</Text>
                    <Button
                      onClick={() => handleIncrement(index)}
                      isDisabled={editMode[index] || part.counter >= 10}
                      size="sm"
                    >
                      +
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => handleAddLines(index, "top")}
                      isDisabled={editMode[index] || part.counter === 0}
                    >
                      위에서 문장 추가
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => handleAddLines(index, "bottom")}
                      isDisabled={editMode[index] || part.counter === 0}
                    >
                      밑에서 문장 추가
                    </Button>
                  </HStack>
                </VStack>
              ))}
            </Grid>

            {/* Complete Selection Button */}
            <Box textAlign="center" mt={2}>
              <Button
                colorScheme="teal"
                onClick={handleCompleteSelection}
                isDisabled={selectedParts.length !== 2}
                isLoading={isGenerating}
                size="md"
              >
                중략 생성하기
              </Button>
            </Box>
          </>
        )}
      </Grid>
      {/* Modal Component */}
      {currentFindPart !== "" && (
        <NovelPartModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          novelText={novelFullText}
          novelTitle={`${selectedWork.author}, 「${selectedWork.title}」`}
          highlightedPart={currentFindPart} // Pass the clicked part
        />
      )}
      {/* Modal for showing the work's content */}
      {selectedWork && (
        <LiteraryWorkModal
          isOpen={isOpen}
          onClose={onClose}
          work={selectedWork} // Pass selected work to the modal
        />
      )}
    </Flex>
  );
}
