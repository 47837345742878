import React, { useState, useEffect } from "react";
import {
  Flex,
  Grid,
  Box,
  VStack,
  Button,
  Heading,
  Textarea,
  Input,
  useToast,
  Text,
  Wrap,
  WrapItem,
  IconButton,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import apiClient from "../../../api/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";

const categoryColors = {
  "문학 작품 - 현대 시": "red",
  "문학 작품 - 현대 소설": "orange",
  "독서 이론": "green",
  "사회(법)": "purple",
  "사회(경제)": "yellow",
  "사회(일반)": "blue",
  인문: "cyan",
  "예술(일반)": "pink",
  "예술(건축)": "gray",
  기술: "lime",
  "과학(생물)": "green",
  "과학(화학)": "orange",
  "과학(물리)": "blue",
  "과학(천체물리)": "indigo",
};

const categories = [
  "문학 작품 - 현대 시",
  "문학 작품 - 현대 소설",
  "독서 이론",
  "사회(법)",
  "사회(경제)",
  "사회(일반)",
  "인문",
  "예술(일반)",
  "예술(건축)",
  "기술",
  "과학(생물)",
  "과학(화학)",
  "과학(물리)",
  "과학(천체물리)",
];

export default function WritePassageDashboard() {
  const [passage, setPassage] = useState({
    title: "",
    contents: "",
    category: "전체", // 기본 카테고리
    questions: [],
  });
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    if (location.state) {
      const { description, title } = location.state;
      if (title && description) {
        setPassage({ ...passage, title, contents: description });
      }
    }
  }, [location.state]);

  const handlePassageChange = (key, value) => {
    setPassage({ ...passage, [key]: value });
  };

  const handleAddQuestion = () => {
    setPassage({
      ...passage,
      questions: [
        ...passage.questions,
        {
          question: '',
          choice_1: '',
          choice_2: '',
          choice_3: '',
          choice_4: '',
          choice_5: '',
          answer: '',
          explanation: '',
        },
      ],
    });
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...passage.questions];
    updatedQuestions.splice(index, 1);
    setPassage({ ...passage, questions: updatedQuestions });
  };

  const handleQuestionChange = (index, key, value) => {
    const updatedQuestions = [...passage.questions];
    updatedQuestions[index][key] = value;
    setPassage({ ...passage, questions: updatedQuestions });
  };


  const handleSubmit = async () => {
    if (
      !passage.title ||
      !passage.contents ||
      !passage.category ||
      passage.questions.some((q) => !q.question)
    ) {
      toast({
        title: "모든 필드를 입력해 주세요.",
        description: "제목, 지문, 카테고리, 문제를 모두 입력해야 합니다.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await apiClient.post(
        `/problem_generator/save_passage_questions/`,
        { passages: [passage] },
        { headers: { "content-type": "application/json" } }
      );
      console.log(response.data);
      navigate("/problem-bank");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
        <Box mb={8}>
          <Heading size="lg" color="teal.600">
            지문 및 문제 입력
          </Heading>
          <Text color="gray.600" mt={2}>
            직접 지문과 문제를 입력하세요
          </Text>
        </Box>
        <Box
          bg="white"
          borderWidth="1px"
          borderRadius="xl"
          p={5}
          mb={6}
          boxShadow="md"
        >
          <VStack spacing={6}>
            <Input
              value={passage.title}
              onChange={(e) => handlePassageChange("title", e.target.value)}
              placeholder="지문 제목을 입력하세요..."
              mb={4}
              isDisabled={isLoading}
              borderRadius="full"
            />
            <Textarea
              value={passage.contents}
              onChange={(e) => handlePassageChange("contents", e.target.value)}
              placeholder="지문 내용을 입력하세요..."
              height="200px"
              resize="vertical"
              mb={4}
              isDisabled={isLoading}
              borderRadius="lg"
            />
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              카테고리 선택
            </Text>
            <Wrap spacing={4} justify="center">
              {categories.map((cat) => (
                <WrapItem key={cat}>
                  <Button
                    colorScheme={categoryColors[cat]}
                    variant={passage.category === cat ? "solid" : "outline"}
                    onClick={() => handlePassageChange("category", cat)}
                    borderRadius="full"
                    boxShadow="sm"
                    _hover={{ boxShadow: "md" }}
                    _focus={{ boxShadow: "outline" }}
                  >
                    {cat}
                  </Button>
                </WrapItem>
              ))}
            </Wrap>
            <VStack spacing={4} width="100%">
            {passage.questions.map((question, index) => (
              <Box
                key={index}
                width="100%"
                borderWidth="1px"
                borderRadius="xl"
                p={4}
                bg="white"
                boxShadow="md"
                position="relative"
              >
                <Textarea
                  value={question.question}
                  onChange={(e) => handleQuestionChange(index, "question", e.target.value)}
                  placeholder="문제를 입력하세요..."
                  mb={4}
                  isDisabled={isLoading}
                  borderRadius="lg"
                />
                 {[...Array(5)].map((_, i) => (
                  <Input
                    key={`choice_${i + 1}`}
                    value={question[`choice_${i + 1}`]}
                    onChange={(e) => handleQuestionChange(index, `choice_${i + 1}`, e.target.value)}
                    placeholder={`선택지 ${i + 1}`}
                    mb={3}
                    isDisabled={isLoading}
                    borderRadius="full"
                  />
                ))}
                <Input
                  value={question.answer}
                  onChange={(e) => handleQuestionChange(index, "answer", e.target.value)}
                  placeholder="정답"
                  mb={4}
                  isDisabled={isLoading}
                  borderRadius="full"
                />
                <Textarea
                  value={question.explanation}
                  onChange={(e) => handleQuestionChange(index, "explanation", e.target.value)}
                  placeholder="해설을 입력하세요..."
                  mb={4}
                  isDisabled={isLoading}
                  borderRadius="lg"
                />
                <IconButton
                  aria-label="Remove question"
                  icon={<MdOutlineDelete />}
                  position="absolute"
                  bottom="10px"
                  right="10px"
                  onClick={() => handleRemoveQuestion(index)}
                  colorScheme="red"
                  variant="outline"
                  borderRadius="full"
                />
              </Box>
            ))}
          </VStack>
          <Button onClick={handleAddQuestion} colorScheme="teal" isDisabled={isLoading} borderRadius="full">
            문제 추가
          </Button>
            <Button
              colorScheme="teal"
              onClick={handleSubmit}
              isLoading={isLoading}
              loadingText="제출 중"
              borderRadius="full"
              width="100%"
            >
              제출
            </Button>
          </VStack>
        </Box>
      </Grid>
    </Flex>
  );
}
