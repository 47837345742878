import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Checkbox,
  Grid,
  Wrap,
  WrapItem,
  Textarea,
  useColorModeValue,
  VStack,
  Input,
  IconButton,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import apiClient from "../../../api/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

export default function CreateNovelSup() {
  const [keywords, setKeywords] = useState([]); // 서버에서 받아온 키워드
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [isSaving, setIsSaving] = useState(false); // 로딩 상태
  const [title, setTitle] = useState(""); // 지문 제목
  const [initialPassage, setInitialPassage] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]); // 선택된 키워드
  const [supplement, setSupplement] = useState(null); // 보충된 정보
  const [question, setQuestion] = useState(null); // Single question
  const [solution, setSolution] = useState(null); // Single solution
  const [editedQuestion, setEditedQuestion] = useState(null); // For edit mode
  const [editedSolution, setEditedSolution] = useState(null); // For edit mode
  const [editMode, setEditMode] = useState(false); // 편집 모드 상태
  const [passageId, setPassageId] = useState(null); // 지문 ID
  const [option, setOption] = useState("현대 소설"); // 옵션 (카테고리)
  const [rating, setRating] = useState(null); // 별점 상태
  const [comment, setComment] = useState(""); // 의견 상태
  const [sendFeedback, setSendFeedback] = useState(false); // Whether to send the rating and comment
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  // 색상 모드에 따라 다른 색상을 사용
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const inputBg = useColorModeValue("gray.50", "gray.800");

  // 페이지 로드 시, 전달받은 state에서 title, passage, keywords를 설정
  useEffect(() => {
    if (location.state) {
      const { title, passage, keywords, passage_id, option } = location.state;
      setTitle(title);
      setPassageId(passage_id);
      setOption(option || "현대 소설");
      // Ensure passage is properly extracted from the contents field if it's an object
      const passageText =
        typeof passage === "string" ? passage : passage.contents || "";

      // Split passage into paragraphs only if it's a string
      setInitialPassage(passageText);

      // Set keywords directly since they are already formatted
      if (Array.isArray(keywords)) {
        setKeywords(keywords);
      } else {
        setKeywords([]);
      }
    } else {
      navigate("/error", {
        state: {
          errorMessage: "잘못된 접근입니다. 먼저 문학 작품을 선택해주세요.",
        },
      });
    }
  }, [location.state, navigate]);

  const handleSupplementGenerate = () => {
    if (selectedKeywords.length === 0) {
      alert("하나 이상의 테마를 선택하세요.");
      return;
    }

    setIsLoading(true);
    setQuestion([]);
    setSolution([]);
    setSupplement(null);
    setIsLoading(true);
    const data = {
      title,
      passage: initialPassage,
      keywords: JSON.stringify(selectedKeywords), // 키워드를 JSON 문자열로 변환
      passage_id: passageId,
      option: option || "현대 소설",
    };

    const token = localStorage.getItem("token");

    apiClient
      .post("/problem_generator/generate_supplement_novels/", data) // baseURL이 설정되어 있으므로, 상대 경로만 사용
      .then((response) => {
        setQuestion(response.data.questions[0]);
        setSolution(response.data.solutions[0]);
        if (response.data.questions.length > 0) {
          setSupplement(response.data.questions[0].supplement);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setSupplement(null);
        setQuestion(null);
        setSolution(null);
        setIsLoading(false);
        alert("보기 & 문제 생성 중 오류가 발생했습니다. 다시 시도해주세요.");
        console.error("Error generating supplement:", error);
      });
  };

  const handleKeywordSelection = (selectedKeyword) => {
    setSelectedKeywords([selectedKeyword]); // 선택한 하나의 키워드만 유지
  };

  const enterEditMode = () => {
    setEditMode(true);
    setEditedQuestion({ ...question });
    setEditedSolution({ ...solution });
  };

  const saveEdits = () => {
    setQuestion({ ...editedQuestion });
    setSolution({ ...editedSolution });
    setEditMode(false);
  };

  // 문제 저장 함수
  const handleSaveQuestion = () => {
    setIsSaving(true);
    const questionCategory = "소설 - 외적 준거에 따른 작품 감상";

    const data = {
      passages: [
        {
          passage_id: passageId,
          questions: [
            {
              question: question.question,
              category: questionCategory,
              choice_1: question.choice_1,
              choice_2: question.choice_2,
              choice_3: question.choice_3,
              choice_4: question.choice_4,
              choice_5: question.choice_5,
              order: question.order,
              supplement: supplement,
              answer: solution.answer,
              explanation: solution.explanation,
            },
          ],
        },
      ],
    };

    if (sendFeedback) {
      if (rating >= 0 && rating != null) {
        data.passages[0].rating = rating;
      }

      if (comment.trim()) {
        data.passages[0].feedback = comment; // Add feedback only if comment has content
      }
    }

    const token = localStorage.getItem("token");

    apiClient
      .post("/problem_generator/save_questions/", data)
      .then((response) => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        navigate("/create-question", {
          state: {
            passage_id: passageId, // This maps to passage_id
            title: title, // This maps to title
            category: option, // This maps to option
            contents: initialPassage, // This maps to passageText (or contents)
          },
        });
        // 저장 후 초기화 또는 다른 동작을 추가할 수 있습니다.
        // navigate('/problem-bank'); // 문제 은행 페이지로 이동 (필요 시)
      })
      .catch((error) => {
        console.error("Error saving questions:", error);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box>
          <Heading size="lg" color="teal.600">
            보기 & 문제 생성하기
          </Heading>
          <Text color="gray.600" mt={2}>
            선택된 테마를 기준으로 보기를 생성하세요
          </Text>
        </Box>
        <Box
          bg="white"
          borderWidth="1px"
          borderRadius="xl"
          p={5}
          mb={2}
          boxShadow="md"
        >
          <Text mb={2} color={textColor}>
            제목: {title}
          </Text>

          <Text mb={2} color={textColor}>
            지문
          </Text>
          <HStack spacing={4}>
            <Box
              flex="1"
              mb={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              height="900px" // 일정한 높이 설정
              overflow="auto" // 텍스트가 넘치면 스크롤 가능
            >
              {initialPassage
                ?.split("\n\n")
                .slice(
                  0,
                  Math.ceil(
                    initialPassage.split("\n\n").length / 2 // Take only the first half
                  )
                )
                .map((paragraph, index) => (
                  <Text
                    key={index}
                    size="sm"
                    bg={inputBg}
                    borderRadius="xl"
                    textAlign="justify"
                    style={{
                      whiteSpace: "pre-wrap", // 원래 공백과 줄바꿈을 유지
                      letterSpacing: "0.08em",
                      wordSpacing: "0.15em",
                      wordBreak: "break-word",
                    }}
                  >
                    {(index === 0 ? "" : "\n\n") + paragraph}
                  </Text>
                ))}
            </Box>

            <Box
              flex="1"
              mb={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="lg"
              p={4}
              height="900px" // 일정한 높이 설정
              overflow="auto" // 텍스트가 넘치면 스크롤 가능
            >
              {initialPassage
                ?.split("\n\n")
                .slice(Math.ceil(initialPassage.split("\n\n").length / 2))
                .map((paragraph, index) => (
                  <Text
                    size="sm"
                    bg={inputBg}
                    key={index}
                    borderRadius="xl"
                    textAlign="justify"
                    style={{
                      whiteSpace: "pre-wrap",
                      letterSpacing: "0.08em",
                      wordSpacing: "0.15em",
                      marginBottom: "16px", // 여백 추가
                    }}
                  >
                    {(index === 0 ? "" : "\n\n") + paragraph}
                  </Text>
                ))}
            </Box>
          </HStack>

          <Text mb={2} color={textColor}>
            선택된 테마
          </Text>
          <Wrap mt={4}>
            {keywords.map(({ key, text }, index) => (
              <WrapItem key={index}>
                <Checkbox
                  isChecked={selectedKeywords[0] === text} // 현재 선택된 키워드와 비교
                  onChange={() => handleKeywordSelection(text)}
                  colorScheme="teal"
                >
                  {text}
                </Checkbox>
              </WrapItem>
            ))}
          </Wrap>

          <Button
            colorScheme="teal"
            onClick={handleSupplementGenerate}
            width="100%"
            isLoading={isLoading}
            loadingText="보기 & 문제 생성 중..."
            mt={6}
            borderRadius="full"
          >
            보기 & 문제 생성
          </Button>
        </Box>
        <HStack spacing={2} width="100%" alignItems="start" pl={0} pr={0}>
          {!isLoading && supplement ? (
            <VStack flex="4">
              <Box
                bg={bgColor}
                borderWidth="1px"
                borderRadius="xl"
                p={4}
                mt={0}
                width={{ base: "100%", md: "100%" }}
              >
                <Heading size="md" mb={4}>
                  &lt;보기&gt;
                </Heading>
                <Textarea
                  value={supplement}
                  onChange={(e) => setSupplement(e.target.value)}
                  size="sm"
                  minHeight="125px"
                  bg={inputBg}
                  borderRadius="xl"
                />
              </Box>
              <Box
                bg={bgColor}
                borderWidth="1px"
                borderRadius="xl"
                p={4}
                mt={0}
                width={{ base: "100%", md: "100%" }}
              >
                <Text fontWeight="bold">별점 (5점 만점):</Text>
                <HStack>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Box
                      key={star}
                      position="relative"
                      display="inline-block"
                      onMouseDown={(e) => {
                        const box = e.target.getBoundingClientRect();
                        const clickX = e.clientX - box.left;
                        const starWidth = box.width;

                        // Determine if click was on left or right side of the star
                        if (clickX < starWidth / 2) {
                          setRating(star - 0.5); // Half star
                        } else {
                          setRating(star); // Full star
                        }
                      }}
                    >
                      <IconButton
                        icon={
                          rating >= star ? (
                            <FaStar />
                          ) : rating >= star - 0.5 ? (
                            <FaStarHalfAlt />
                          ) : (
                            <FaRegStar />
                          )
                        }
                        variant="ghost"
                        aria-label={`Rate ${star}`}
                      />
                    </Box>
                  ))}
                </HStack>

                <Text fontWeight="bold" mt={2} mb={1}>
                  추가 의견:
                </Text>
                <Textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="의견을 입력해주세요."
                  bg={inputBg}
                  borderRadius="xl"
                />

                <Checkbox
                  isChecked={sendFeedback}
                  onChange={(e) => setSendFeedback(e.target.checked)}
                  colorScheme="teal"
                  mt={2}
                >
                  별점과 의견을 지문과 함께 저장합니다.
                </Checkbox>
              </Box>
            </VStack>
          ) : isLoading ? null : null}

          {!isLoading && question && solution && (
            <Box
              bg={bgColor}
              borderWidth="1px"
              borderRadius="xl"
              p={5}
              mt={0}
              width={{ base: "100%", md: "80%" }}
              flex="6"
            >
              <Heading size="md" mb={4}>
                생성된 보기 문제
              </Heading>
              <Box mb={4}>
                {editMode ? (
                  <Box>
                    <Input
                      value={editedQuestion?.question || ""}
                      onChange={(e) =>
                        setEditedQuestion({
                          ...editedQuestion,
                          question: e.target.value,
                        })
                      }
                      bg={inputBg}
                      mb={2}
                    />
                    <VStack spacing={2} align="start">
                      {[1, 2, 3, 4, 5].map((n) => (
                        <Input
                          key={n}
                          value={editedQuestion?.["choice_" + n] || ""}
                          onChange={(e) =>
                            setEditedQuestion({
                              ...editedQuestion,
                              ["choice_" + n]: e.target.value,
                            })
                          }
                          bg={inputBg}
                        />
                      ))}
                    </VStack>

                    {/* 정답 (Answer) Field */}
                    <Text fontWeight="bold">
                      정답:
                      <Input
                        value={editedSolution?.answer || ""}
                        onChange={(e) =>
                          setEditedSolution({
                            ...editedSolution,
                            answer: e.target.value,
                          })
                        }
                        bg={inputBg}
                        mt={2}
                      />
                    </Text>

                    {/* 해설 (Explanation) Field */}
                    <Text fontWeight="bold">
                      해설:
                      <Textarea
                        value={editedSolution?.explanation || ""}
                        onChange={(e) =>
                          setEditedSolution({
                            ...editedSolution,
                            explanation: e.target.value,
                          })
                        }
                        bg={inputBg}
                        mt={2}
                      />
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text mt={2} mb={0} fontWeight="bold">
                      {question.question}
                    </Text>
                    <VStack spacing={2} align="start" mt={2}>
                      {[1, 2, 3, 4, 5].map((n) => (
                        <Text key={n}>
                          {n}. {question["choice_" + n]}
                        </Text>
                      ))}
                    </VStack>
                    {/* Display 정답 (Answer) */}
                    <Text mt={4}>
                      <strong>정답:</strong> {solution.answer}
                    </Text>

                    {/* Display 해설 (Explanation) */}
                    <Text mt={4}>
                      <strong>해설:</strong> {solution.explanation}
                    </Text>
                  </Box>
                )}
              </Box>
              {editMode ? (
                <Button
                  leftIcon={<CheckIcon />}
                  colorScheme="teal"
                  mt={3}
                  onClick={saveEdits}
                >
                  완료
                </Button>
              ) : (
                <HStack spacing={4} mt={3}>
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    size="sm"
                    onClick={enterEditMode}
                  />
                  <Button
                    colorScheme="teal"
                    onClick={handleSaveQuestion}
                    isLoading={isSaving}
                    borderRadius="full"
                  >
                    문제 저장 & 추가 문제 생성
                  </Button>
                </HStack>
              )}
            </Box>
          )}
        </HStack>
      </Grid>
    </Flex>
  );
}
