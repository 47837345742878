import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";

const NovelPartModal = ({
  isOpen,
  onClose,
  novelText,
  highlightedPart,
  novelTitle,
}) => {
  const modalBodyRef = useRef(null);
  const [processedText, setProcessedText] = useState("");

  // Highlight the selected part in the novel text
  const highlightText = (text, part) => {
    const normalize = (str) => str.replace(/[\r\n\s]+/g, "").toLowerCase();

    const normalizedText = normalize(text); // Cleaned version of the full text
    //const normalizedPart = normalize(part.text.replace(/^\d+\.\s*/, "")); // Cleaned version of the part to match
    const normalizedPart = normalize(part.text);

    const startIndex = normalizedText.indexOf(normalizedPart);
    if (startIndex === -1) {
      console.log("No matches found after normalization.");
      return text;
    }

    let matchLength = 0;
    let originalStartIndex = 0;

    for (let i = 0; i < text.length; i++) {
      if (!/\s/.test(text[i]) && text[i] !== "\r" && text[i] !== "\n") {
        if (matchLength === startIndex) {
          originalStartIndex = i;
          break;
        }
        matchLength++;
      }
    }

    let matchEndIndex = originalStartIndex;
    while (
      matchLength < startIndex + normalizedPart.length &&
      matchEndIndex < text.length
    ) {
      if (
        !/\s/.test(text[matchEndIndex]) &&
        text[matchEndIndex] !== "\r" &&
        text[matchEndIndex] !== "\n"
      ) {
        matchLength++;
      }
      matchEndIndex++;
    }

    const originalMatch = text.slice(originalStartIndex, matchEndIndex);
    const regex = new RegExp(
      originalMatch.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), // Escape special characters
      "gi"
    );

    return text.replace(
      regex,
      (match) => `<mark style="background: yellow;">${match}</mark>`
    );
  };

  // Process text when modal is opened or highlightedPart changes
  useEffect(() => {
    if (isOpen && highlightedPart) {
      const highlightedText = highlightText(novelText, highlightedPart);
      setProcessedText(highlightedText);
    }
  }, [isOpen, highlightedPart, novelText]);

  // Scroll to the first highlight when the button is clicked
  const scrollToHighlight = () => {
    if (modalBodyRef.current) {
      const firstHighlight = modalBodyRef.current.querySelector("mark");
      if (firstHighlight) {
        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        console.log("No highlighted text found.");
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          px={4}
          py={3}
          borderBottom="1px solid #E2E8F0"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="lg" fontWeight="bold">
            {novelTitle}
          </Text>
          <Box display="flex" gap={2}>
            <Button
              size="sm"
              colorScheme="teal"
              onClick={scrollToHighlight}
              variant="outline"
            >
              하이라이트로 이동
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={onClose}
              variant="outline"
            >
              닫기
            </Button>
          </Box>
        </ModalHeader>
        <ModalBody
          px={3}
          py={3}
          ref={modalBodyRef}
          overflowY="auto"
          maxHeight="600px"
        >
          <Box
            style={{ whiteSpace: "pre-wrap", textAlign: "left" }}
            dangerouslySetInnerHTML={{
              __html: processedText,
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NovelPartModal;
