import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Grid,
  HStack,
  Input,
  IconButton,
  useToast,
  Textarea,
  useColorModeValue,
  useDisclosure,
  Checkbox,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import LiteraryWorkModal from "./components/LiteraryWorkModal";
import WorkComparisonSection from "./components/WorkComparisonSection";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import apiClient from "../../../api/axiosInstance";

export default function PoemExtractLiteratureDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedWork, setSelectedWork] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // 로딩 상태
  const [keyword, setKeyword] = useState("");
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [originalWork, setOriginalWork] = useState(null);
  const [retrievedWorks, setRetrievedWorks] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); //For Work Comparison Slider
  const [boxHeight, setBoxHeight] = useState(null); // To store the height of originalWork box
  const originalWorkRef = useRef(null); // Ref for the original work box
  const [supplement, setSupplement] = useState(null); // 보충된 정보 / 보기
  const [passage, setPassage] = useState(null);
  const [title, setTitle] = useState(null);
  const [databaseId, setDatabaseId] = useState(null);
  const [editMode, setEditMode] = useState(false); // 편집 모드 상태
  const [question, setQuestion] = useState(null); // Single question
  const [solution, setSolution] = useState(null); // Single solution
  const [editedQuestion, setEditedQuestion] = useState(null); // For edit mode
  const [editedSolution, setEditedSolution] = useState(null); // For edit mode
  const [rating, setRating] = useState(null); // 별점 상태
  const [comment, setComment] = useState(""); // 의견 상태
  const [sendFeedback, setSendFeedback] = useState(false); // Whether to send the rating and comment
  const toast = useToast();

  // 색상 모드에 따라 다른 색상을 사용
  const bgColor = useColorModeValue("white", "gray.700");
  const inputBg = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    // Check if work and category are passed in from the previous page
    if (location.state) {
      const { work, category } = location.state;

      if (!work || !category) {
        // Redirect to a specific error page with the error message
        navigate("/error", {
          state: {
            errorMessage:
              "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
          },
        });
      } else {
        // Set the selected work if both work and category exist
        setSelectedWork(work);
        setSelectedCategory(category);
        console.log(work.suggested_keywords);
      }
    } else {
      // Redirect to error if location.state is not present
      navigate("/error", {
        state: {
          errorMessage:
            "문학 작품이 선택되지 않았습니다. 먼저 문학 작품을 선택해주세요.",
        },
      });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (originalWork && retrievedWorks.length > 0) {
      // Set the height after both originalWork and retrievedWorks are set
      const timeoutId = setTimeout(() => {
        if (originalWorkRef.current) {
          setBoxHeight(originalWorkRef.current.offsetHeight);
        }
      }, 0); // Ensure it runs after render with a small delay

      return () => clearTimeout(timeoutId); // Cleanup to avoid memory leaks
    }
  }, [originalWork, retrievedWorks]); // Now, the effect triggers only when both are available

  const handleRetrieveContent = () => {
    setRetrievedWorks([]);
    if (keyword.trim() === "") return;

    setIsRetrieving(true); // Disable the button while processing

    // API call for poetry
    apiClient
      .post("/problem_generator/retrieve_similar_poems/", {
        text: keyword,
        selected_work: selectedWork,
      })
      .then((response) => {
        setOriginalWork(response.data.pre_selected_work);
        setRetrievedWorks(response.data.retrieved_works);
      })
      .catch((error) => {
        console.error(
          "Error fetching similar poems:",
          error.response?.data || error.message
        );
      })
      .finally(() => {
        setIsRetrieving(false); // Re-enable the button when request is completed
      });
  };

  const handleNext = () => {
    if (currentIndex < retrievedWorks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const formatPassages = (passage1, passage2) => {
    // Format the first passage (가)
    const formattedPassage1 = `(가)\r\n${passage1.content.trim()}\r\n- ${
      passage1.author
    }, 「${passage1.title}」 -`;

    // Format the second passage (나)
    const formattedPassage2 = `(나)\r\n${passage2.content.trim()}\r\n- ${
      passage2.author
    }, 「${passage2.title}」 -`;

    // Return both passages as a single string with \r\n\r\n between them
    return `${formattedPassage1}\n\n${formattedPassage2}`;
  };

  const handleCompleteSelection = () => {
    setIsLoading(true);
    setQuestion(null);
    setSolution(null);
    setPassage(null);
    setSupplement(null);
    const finalSupplement = retrievedWorks[currentIndex].supplement.comparison;
    const finalPassage = formatPassages(
      originalWork,
      retrievedWorks[currentIndex]
    );

    const finalTitle = `${originalWork.author}, 「${originalWork.title}」 / ${retrievedWorks[currentIndex].author}, 「${retrievedWorks[currentIndex].title}」`;

    const data = {
      passage: finalPassage,
      keywords: keyword,
      passage_id: originalWork.id,
      option: selectedCategory,
      supplement: finalSupplement,
      title: finalTitle,
    };

    apiClient
      .post("/problem_generator/generate_problem_poems/", data)
      .then((response) => {
        setQuestion(response.data.questions[0]);
        setSolution(response.data.solutions[0]);
        setDatabaseId(response.data.databaseId);
        setPassage(finalPassage);
        setSupplement(finalSupplement);
        setTitle(finalTitle);
        setIsLoading(false);
      })
      .catch((error) => {
        setQuestion(null);
        setSolution(null);
        setIsLoading(false);
        alert("문제 생성 중 오류가 발생했습니다. 다시 시도해주세요.");
        console.error("Error generating supplement:", error);
      });
  };

  const enterEditMode = () => {
    setEditMode(true);
    setEditedQuestion({ ...question });
    setEditedSolution({ ...solution });
  };

  const saveEdits = () => {
    setQuestion({ ...editedQuestion });
    setSolution({ ...editedSolution });
    setEditMode(false);
  };

  const handleKeywordClick = (newKeyword) => {
    setKeyword((prev) =>
      prev.trim() === "" ? newKeyword : `${prev}, ${newKeyword}`
    ); // Append with a comma if input is not empty
  };

  // 문제 저장 함수
  const handleSaveQuestion = () => {
    setIsSaving(true);

    const questionCategory = "운문 - 외적 준거에 따른 작품 감상";

    const data = {
      passages: [
        {
          passage_id: databaseId,
          questions: [
            {
              question: question.question,
              category: questionCategory,
              choice_1: question.choice_1,
              choice_2: question.choice_2,
              choice_3: question.choice_3,
              choice_4: question.choice_4,
              choice_5: question.choice_5,
              order: question.order,
              supplement: supplement,
              answer: solution.answer,
              explanation: solution.explanation,
            },
          ],
        },
      ],
    };

    if (sendFeedback) {
      if (rating >= 0 && rating != null) {
        data.passages[0].rating = rating;
      }

      if (comment.trim()) {
        data.passages[0].feedback = comment; // Add feedback only if comment has content
      }
    }

    const token = localStorage.getItem("token");

    const categoryMap = {
      modern_poetry: "문학 작품 - 현대 시",
      classic_poetry: "문학 작품 - 고전 시",
    };

    apiClient
      .post("/problem_generator/save_questions/", data)
      .then((response) => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/create-question", {
          state: {
            passage_id: databaseId,
            title: title,
            category: categoryMap[selectedCategory],
            contents: passage,
          },
        });
      })
      .catch((error) => {
        console.error("Error saving questions:", error);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box>
          <Heading size="lg" color="teal.600">
            키워드 입력
          </Heading>
          <Text color="gray.600" mt={2}>
            지문 발췌와 작품 생성을 위한 키워드 문구를 입력해주세요
          </Text>
        </Box>
        {selectedWork && (
          <HStack spacing={0} w="100%" alignItems="stretch">
            {/* Selected Work Section */}
            <HStack flex="6">
              <Box px={6} w="100%">
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  boxShadow="md"
                  bg="gray.50"
                  _hover={{
                    boxShadow: "lg",
                    bg: "gray.50",
                  }}
                  transition="all 0.3s ease"
                >
                  <HStack justify="space-between" align="center">
                    <VStack align="start" spacing={2}>
                      <Text fontSize="lg" fontWeight="bold" color="teal.700">
                        {selectedWork.author}, 「{selectedWork.title}」
                      </Text>
                      <Text color="gray.500">
                        {selectedWork.description || "작품 설명(EBS 연계 등)"}
                      </Text>
                    </VStack>

                    <HStack>
                      <Button
                        borderRadius="full"
                        border="1px solid"
                        borderColor="gray.300"
                        bg="gray.50"
                        color="gray.500"
                        _hover={{
                          bg: "gray.100",
                        }}
                        onClick={onOpen}
                      >
                        작품 보기
                      </Button>
                      <Button
                        borderRadius="full"
                        colorScheme="red"
                        onClick={() =>
                          navigate("/passage-literature-create/", {
                            state: { initialCategory: selectedCategory },
                          })
                        }
                      >
                        작품 재선택
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
              </Box>
            </HStack>

            {/* Keyword Input Section */}
            <VStack
              flex="4"
              w="100%"
              h="100%"
              spacing={2}
              p={0}
              justify="center" // Vertical centering
              align="center"
            >
              <HStack spacing={1} w="100%" h="100%" justify="center">
                {selectedWork.suggested_keywords
                  .split(",")
                  .map((keyword, index) => (
                    <Button
                      key={index}
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      onClick={() => handleKeywordClick(keyword.trim())} // Pass trimmed keyword
                    >
                      {keyword.trim()}
                    </Button>
                  ))}
              </HStack>
              <HStack spacing={2} w="100%" h="100%" justify="center">
                <Input
                  placeholder="키워드 문구 입력"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  textAlign="center"
                  flex="2"
                />
                <Button
                  borderRadius="full"
                  colorScheme="teal"
                  onClick={handleRetrieveContent}
                  isDisabled={isRetrieving}
                  isLoading={isRetrieving}
                  flex="1"
                >
                  작품과 보기 발췌
                </Button>
              </HStack>
            </VStack>
          </HStack>
        )}
        {originalWork && retrievedWorks.length > 0 && !isRetrieving && (
          <>
            <Box>
              <Heading size="lg" color="teal.600">
                작품 선택
              </Heading>
              <Text color="gray.600" mt={2}>
                문제 생성을 위한 두 번쨰 작품을 선택해주세요
              </Text>
            </Box>

            {/* Display Original Work, Work Comparison Section, and Buttons in a single row */}
            <HStack spacing={0} width="100%" alignItems="start" pl={6} pr={3}>
              {/* Original Work (가) */}
              <Box
                bg="white"
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                boxShadow="md"
                flex="3"
                whiteSpace="pre-wrap"
                ref={originalWorkRef}
              >
                <Text fontWeight="bold" my={0}>
                  (가)
                </Text>
                <Text my={0}>{originalWork.content}</Text>
                <Text textAlign="right" my={0}>
                  - {originalWork.author}, 「{originalWork.title}」 -
                </Text>
              </Box>

              {/* Left Arrow Button */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={`${boxHeight}px`}
                p={0}
                m={0}
                borderRadius="none"
              >
                <Button
                  onClick={handlePrev}
                  backgroundColor="transparent" // Set the button's background to transparent
                  color="teal.500"
                  _hover={{ backgroundColor: "transparent", color: "teal.600" }} // Ensure hover doesn't change background
                  _focus={{ boxShadow: "none" }} // Remove focus outline
                  isDisabled={currentIndex === 0}
                  p={0}
                  m={0}
                >
                  <ChevronLeftIcon boxSize={20} />
                </Button>
              </Box>

              {/* Work Comparison Section with dynamic retrievedWork */}
              <VStack
                spacing={2}
                align="stretch"
                flex="7" // Handle overall flex at this level
                maxHeight={`${boxHeight}px`} // Set max height here
                height="auto"
                overflow="hidden" // Ensure scrolling happens inside the box
              >
                {/* WorkComparisonSection with scrollable content */}
                <Box
                  bg="white"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  boxShadow="md"
                  height="100%" // Ensure it takes full available height
                  overflowY="auto" // Enable scrolling for content overflow
                  mb={0}
                >
                  <WorkComparisonSection
                    retrievedWork={retrievedWorks[currentIndex]} // Show current retrievedWork
                  />
                </Box>

                {/* Progress Indicator (n/5) 
                <Text textAlign="center" fontSize="lg">{`${
                  currentIndex + 1
                } / ${retrievedWorks.length}`}</Text>
                 */}
              </VStack>
              {/* Right Arrow Button */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={`${boxHeight}px`}
                p={0}
                m={0}
                borderRadius="none"
              >
                <Button
                  onClick={handleNext}
                  isDisabled={currentIndex === retrievedWorks.length - 1}
                  backgroundColor="transparent" // Set the button's background to transparent
                  color="teal.500"
                  _hover={{ backgroundColor: "transparent", color: "teal.600" }} // Ensure hover doesn't change background
                  _focus={{ boxShadow: "none" }} // Remove focus outline
                  p={0}
                  m={0}
                >
                  <ChevronRightIcon boxSize={20} />
                </Button>
              </Box>
            </HStack>
            {/* Centered Button to Pass the Works to the Next Page */}
            <Box textAlign="center" mt={0}>
              <Button
                colorScheme="teal"
                onClick={handleCompleteSelection}
                isLoading={isLoading}
              >
                보기 문제 생성
              </Button>
            </Box>
          </>
        )}
        <HStack spacing={2} width="100%" alignItems="start" pl={6} pr={3}>
          {!isLoading && supplement ? (
            <VStack flex="4">
              <Box
                bg={bgColor}
                borderWidth="1px"
                borderRadius="xl"
                p={4}
                mt={0}
                width={{ base: "100%", md: "100%" }}
              >
                <Heading size="md" mb={4}>
                  &lt;보기&gt;
                </Heading>
                <Textarea
                  value={supplement}
                  onChange={(e) => setSupplement(e.target.value)}
                  size="sm"
                  minHeight="125px"
                  bg={inputBg}
                  borderRadius="xl"
                />
              </Box>
              <Box
                bg={bgColor}
                borderWidth="1px"
                borderRadius="xl"
                p={4}
                mt={0}
                width={{ base: "100%", md: "100%" }}
              >
                <Text fontWeight="bold">별점 (5점 만점):</Text>
                <HStack>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Box
                      key={star}
                      position="relative"
                      display="inline-block"
                      onMouseDown={(e) => {
                        const box = e.target.getBoundingClientRect();
                        const clickX = e.clientX - box.left;
                        const starWidth = box.width;

                        // Determine if click was on left or right side of the star
                        if (clickX < starWidth / 2) {
                          setRating(star - 0.5); // Half star
                        } else {
                          setRating(star); // Full star
                        }
                      }}
                    >
                      <IconButton
                        icon={
                          rating >= star ? (
                            <FaStar />
                          ) : rating >= star - 0.5 ? (
                            <FaStarHalfAlt />
                          ) : (
                            <FaRegStar />
                          )
                        }
                        variant="ghost"
                        aria-label={`Rate ${star}`}
                      />
                    </Box>
                  ))}
                </HStack>

                <Text fontWeight="bold" mt={2} mb={1}>
                  추가 의견:
                </Text>
                <Textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="의견을 입력해주세요."
                  bg={inputBg}
                  borderRadius="xl"
                />

                <Checkbox
                  isChecked={sendFeedback}
                  onChange={(e) => setSendFeedback(e.target.checked)}
                  colorScheme="teal"
                  mt={2}
                >
                  별점과 의견을 지문과 함께 저장합니다.
                </Checkbox>
              </Box>
            </VStack>
          ) : isLoading ? null : null}

          {!isLoading && question && solution && (
            <Box
              bg={bgColor}
              borderWidth="1px"
              borderRadius="xl"
              p={5}
              mt={0}
              width={{ base: "100%", md: "80%" }}
              flex="6"
            >
              <Heading size="md" mb={4}>
                생성된 보기 문제
              </Heading>
              <Box mb={4}>
                {editMode ? (
                  <Box>
                    <Input
                      value={editedQuestion?.question || ""}
                      onChange={(e) =>
                        setEditedQuestion({
                          ...editedQuestion,
                          question: e.target.value,
                        })
                      }
                      bg={inputBg}
                      mb={2}
                    />
                    <VStack spacing={2} align="start">
                      {[1, 2, 3, 4, 5].map((n) => (
                        <Input
                          key={n}
                          value={editedQuestion?.["choice_" + n] || ""}
                          onChange={(e) =>
                            setEditedQuestion({
                              ...editedQuestion,
                              ["choice_" + n]: e.target.value,
                            })
                          }
                          bg={inputBg}
                        />
                      ))}
                    </VStack>

                    {/* 정답 (Answer) Field */}
                    <Text fontWeight="bold">
                      정답:
                      <Input
                        value={editedSolution?.answer || ""}
                        onChange={(e) =>
                          setEditedSolution({
                            ...editedSolution,
                            answer: e.target.value,
                          })
                        }
                        bg={inputBg}
                        mt={2}
                      />
                    </Text>

                    {/* 해설 (Explanation) Field */}
                    <Text fontWeight="bold">
                      해설:
                      <Textarea
                        value={editedSolution?.explanation || ""}
                        onChange={(e) =>
                          setEditedSolution({
                            ...editedSolution,
                            explanation: e.target.value,
                          })
                        }
                        bg={inputBg}
                        mt={2}
                      />
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text mt={2} mb={0} fontWeight="bold">
                      {question.question}
                    </Text>
                    <VStack spacing={2} align="start" mt={2}>
                      {[1, 2, 3, 4, 5].map((n) => (
                        <Text key={n}>
                          {n}. {question["choice_" + n]}
                        </Text>
                      ))}
                    </VStack>
                    {/* Display 정답 (Answer) */}
                    <Text mt={4}>
                      <strong>정답:</strong> {solution.answer}
                    </Text>

                    {/* Display 해설 (Explanation) */}
                    <Text mt={4}>
                      <strong>해설:</strong> {solution.explanation}
                    </Text>
                  </Box>
                )}
              </Box>
              {editMode ? (
                <Button
                  leftIcon={<CheckIcon />}
                  colorScheme="teal"
                  mt={3}
                  onClick={saveEdits}
                >
                  완료
                </Button>
              ) : (
                <HStack spacing={4} mt={3}>
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    size="sm"
                    onClick={enterEditMode}
                  />
                  <Button
                    colorScheme="teal"
                    onClick={handleSaveQuestion}
                    isLoading={isSaving}
                    borderRadius="full"
                  >
                    문제 저장 & 추가 문제 생성
                  </Button>
                </HStack>
              )}
            </Box>
          )}
        </HStack>
      </Grid>
      {/* Modal for showing the work's content */}
      {selectedWork && (
        <LiteraryWorkModal
          isOpen={isOpen}
          onClose={onClose}
          work={selectedWork} // Pass selected work to the modal
        />
      )}
    </Flex>
  );
}
