import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  List,
  ListItem,
  Skeleton,
  Stack,
  Grid,
  HStack,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { CloseIcon } from "@chakra-ui/icons";
import apiClient from "../../../api/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import LiteraryWorkModal from "./components/LiteraryWorkModal";

export default function CreateLiteratureDashboard() {
  const [literaryWorks, setLiteraryWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState("modern_novel");
  const [selectedWork, setSelectedWork] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageNumbersToShow, setMaxPageNumbersToShow] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [workForModal, setWorkForModal] = useState(null);
  const worksPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setMaxPageNumbersToShow(5); // Fewer buttons on smaller screens
      } else {
        setMaxPageNumbersToShow(10); // Default number of buttons
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const categories = [
    //{ label: "고전 소설", value: "classic_novel", dataValue: "classic_novel" },
    { label: "현대 소설", value: "modern_novel", dataValue: "modern_novel" },
    //{ label: "고전 시", value: "classic_poetry", dataValue: "classic_poetry" },
    { label: "현대 시", value: "modern_poetry", dataValue: "modern_poetry" },
  ];

  // Set active category only once based on location.state
  useEffect(() => {
    const initialCategory = location.state?.initialCategory || "modern_novel";
    setActiveCategory(initialCategory);
  }, [location.state]); // Run only once when location.state changes (on mount or navigation)

  useEffect(() => {
    // Fetch works from the new API based on the active category
    setIsLoading(true);
    const token = localStorage.getItem("token");
    apiClient
      .get(
        "/problem_generator/list_literary_works/", // 상대 경로만 사용
        { params: { category: activeCategory } }
      )
      .then((response) => {
        const worksWithCategory = response.data.map((work) => ({
          ...work,
          category: activeCategory,
        }));
        setLiteraryWorks(worksWithCategory); // Set the updated works
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching literary works:", error);
        setIsLoading(false);
      });
  }, [activeCategory]); // Re-fetch works when the category changes

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever the search query changes
  }, [searchQuery]);

  const handleCategoryChange = (categoryValue) => {
    setSelectedWork(null);
    setActiveCategory(categoryValue);
    setCurrentPage(1); // Reset to the first page when category changes
  };

  const handleOpenModal = (literaryWork) => {
    setWorkForModal(literaryWork);
    onOpen();
  };

  // Filter works based on the search query
  const filteredWorks = literaryWorks
    .filter((literaryWork) => literaryWork.category === activeCategory)
    .filter((literaryWork) => {
      if (!searchQuery) return true;

      // Combine work, author, and description into a single search string
      const searchText = `${literaryWork.title} ${literaryWork.author} ${
        literaryWork.description || ""
      }`.toLowerCase();

      return searchText.includes(searchQuery.toLowerCase());
    });

  // Pagination Logic
  const indexOfLastWork = currentPage * worksPerPage;
  const indexOfFirstWork = indexOfLastWork - worksPerPage;
  const currentWorks = filteredWorks.slice(indexOfFirstWork, indexOfLastWork);
  const totalPages = Math.ceil(filteredWorks.length / worksPerPage);

  // Helper function to render page numbers with arrow buttons
  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    // First button
    pages.push(
      <Button
        key="first"
        onClick={() => setCurrentPage(1)}
        borderRadius="full"
        colorScheme="teal"
        variant="outline"
        size="md"
        px={0}
        py={0}
        pb="2px"
        isDisabled={currentPage === 1}
        fontSize="2xl"
      >
        {"\u00AB"}
      </Button>
    );

    // Previous button
    pages.push(
      <Button
        key="prev"
        onClick={() => setCurrentPage(currentPage - 1)}
        borderRadius="full"
        colorScheme="teal"
        variant="outline"
        size="md"
        px={0}
        py={0}
        pb="2px"
        isDisabled={currentPage === 1}
        fontSize="2xl"
      >
        {"\u2039"}
      </Button>
    );

    // Page number buttons
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => setCurrentPage(i)}
          borderRadius="full"
          colorScheme={i === currentPage ? "teal" : "gray"}
          variant={i === currentPage ? "solid" : "outline"}
          size="md"
          mx="1px"
          px={0}
          py="2px"
        >
          {i}
        </Button>
      );
    }

    // Next button
    pages.push(
      <Button
        key="next"
        onClick={() => setCurrentPage(currentPage + 1)}
        borderRadius="full"
        colorScheme="teal"
        variant="outline"
        size="md"
        px={0}
        py={0}
        pb="2px"
        isDisabled={currentPage === totalPages}
        fontSize="2xl"
      >
        {"\u203A"}
      </Button>
    );

    // Last button
    pages.push(
      <Button
        key="last"
        onClick={() => setCurrentPage(totalPages)}
        borderRadius="full"
        colorScheme="teal"
        variant="outline"
        size="md"
        px={0}
        py={0}
        pb="2px"
        isDisabled={currentPage === totalPages}
        fontSize="2xl"
      >
        {"\u00BB"}
      </Button>
    );

    return pages;
  };

  // Function for handling complete selection and navigation
  const handleCompleteSelection = () => {
    if (selectedWork) {
      // Fetch the contents of the selected work
      apiClient
        .get(`/problem_generator/literary_work_contents/${selectedWork.id}/`)
        .then((response) => {
          const updatedWork = {
            ...selectedWork,
            contents: response.data.contents,
            suggested_keywords: response.data.key_themes,
          };

          // Navigate to the next page with the updated work (including contents)
          if (
            selectedWork.category === "modern_poetry" ||
            selectedWork.category === "classic_poetry"
          ) {
            navigate("/poem-passage-literature-extract/", {
              state: {
                work: updatedWork,
                category: selectedWork.category,
              },
            });
          } else if (
            selectedWork.category === "modern_novel" ||
            selectedWork.category === "classic_novel"
          ) {
            navigate("/novel-passage-literature-extract/", {
              state: {
                work: updatedWork,
                category: selectedWork.category,
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching contents:", error);
          // Handle error (optional)
        });
    }
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 4, md: 8 }}
    >
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box>
          <Heading size="lg" color="teal.600">
            작품 선택
          </Heading>
          <Text color="gray.600" mt={2}>
            원하시는 작품을 선택하세요
          </Text>
        </Box>

        {/* Filter Buttons and Search Bar on the Same Line */}
        <HStack spacing={2} w="100%">
          {/* Category Select Buttons - 60% of the space */}
          <HStack spacing={2} flex="6">
            {categories.map((category) => (
              <Button
                key={category.value}
                onClick={() => handleCategoryChange(category.value)}
                borderRadius="full"
                variant={
                  activeCategory === category.value ? "solid" : "outline"
                }
                colorScheme="teal"
              >
                {category.label}
              </Button>
            ))}
          </HStack>

          {/* Search Bar and Button - 40% of the space */}
          <HStack spacing={2} flex="4">
            <InputGroup w="100%">
              <Input
                borderRadius="full"
                placeholder="작가명, 작품명 또는 설명으로 검색"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    aria-label="Clear"
                    icon={<CloseIcon />}
                    size="xs"
                    variant="ghost"
                    onClick={() => setSearchQuery("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
            <IconButton
              aria-label="Search"
              icon={<FaSearch />}
              borderRadius="full"
              colorScheme="teal"
            />
          </HStack>
        </HStack>

        {/* Selected Work Section */}
        {selectedWork && (
          <Box px={6}>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={4}
              boxShadow="md"
              bg="gray.50"
              _hover={{
                boxShadow: "lg",
                bg: "gray.50",
              }}
              transition="all 0.3s ease"
            >
              <HStack justify="space-between" align="center">
                <VStack align="start" spacing={2}>
                  <Text fontSize="lg" fontWeight="bold" color="teal.700">
                    {selectedWork.author}, 「{selectedWork.title}」
                  </Text>
                  <Text color="gray.500">
                    {selectedWork.description || "작품 설명(EBS 연계 등)"}
                  </Text>
                </VStack>

                <HStack>
                  <Button
                    borderRadius="full"
                    border="1px solid"
                    borderColor="gray.300"
                    bg="gray.50"
                    color="gray.500"
                    _hover={{
                      bg: "gray.100",
                    }}
                    onClick={() => handleOpenModal(selectedWork)}
                  >
                    작품 보기
                  </Button>

                  <Button
                    borderRadius="full"
                    colorScheme="red"
                    onClick={() => setSelectedWork(null)}
                  >
                    선택 해제
                  </Button>

                  <Button
                    borderRadius="full"
                    colorScheme="teal"
                    onClick={handleCompleteSelection}
                  >
                    작품 선택 완료
                  </Button>
                </HStack>
              </HStack>
            </Box>
          </Box>
        )}

        {/* List of literary works */}
        <Box
          bg="white"
          borderWidth="1px"
          borderRadius="lg"
          p={6}
          boxShadow="md"
        >
          {isLoading ? (
            <Stack spacing={4}>
              {[1, 2, 3, 4, 5].map((_, index) => (
                <Skeleton height="100px" borderRadius="lg" key={index} />
              ))}
            </Stack>
          ) : (
            <>
              <List spacing={2}>
                {currentWorks.map((literaryWork) => (
                  <ListItem
                    key={literaryWork.id}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={4}
                    boxShadow="sm"
                    _hover={{ boxShadow: "md", bg: "gray.50" }}
                    transition="all 0.3s ease"
                  >
                    <HStack justify="space-between">
                      <VStack align="start" spacing={2}>
                        <Text fontSize="lg" fontWeight="bold" color="teal.700">
                          {literaryWork.author}, 「{literaryWork.title}」
                        </Text>

                        <Text color="gray.500">
                          {literaryWork.description || "작품 설명(EBS 연계 등)"}
                        </Text>
                      </VStack>

                      {/* "작품 보기" and "선택" Buttons together */}
                      <HStack spacing={2}>
                        {/* "작품 보기" Button to open the modal */}

                        <Button
                          borderRadius="full"
                          border="1px solid"
                          borderColor="gray.300"
                          bg="gray.50"
                          color="gray.500"
                          _hover={{
                            bg: "gray.100",
                          }}
                          onClick={() => handleOpenModal(literaryWork)}
                        >
                          작품 보기
                        </Button>
                        {/* Select/Deselect Button that toggles */}
                        <Button
                          borderRadius="full"
                          border="1px solid"
                          borderColor="gray.300"
                          bg={
                            selectedWork?.title === literaryWork.title
                              ? "red.500"
                              : "white"
                          }
                          color={
                            selectedWork?.title === literaryWork.title
                              ? "white"
                              : "gray.800"
                          }
                          _hover={{
                            bg:
                              selectedWork?.title === literaryWork.title
                                ? "red.600"
                                : "gray.100",
                          }}
                          onClick={() => {
                            setSelectedWork(
                              selectedWork?.title === literaryWork.title
                                ? null
                                : literaryWork
                            );
                          }}
                        >
                          {selectedWork?.title === literaryWork.title
                            ? "선택 해제"
                            : "선택"}
                        </Button>
                      </HStack>
                    </HStack>
                  </ListItem>
                ))}
              </List>

              <HStack justify="center" mt={4} spacing={2}>
                {renderPageNumbers()}
              </HStack>
            </>
          )}
        </Box>
      </Grid>
      {/* Modal for showing the work's content */}
      {workForModal && (
        <LiteraryWorkModal
          isOpen={isOpen}
          onClose={onClose}
          work={workForModal} // Pass selected work to the modal
        />
      )}
    </Flex>
  );
}
