import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";

export default function LiteraryWorkModal({ isOpen, onClose, work }) {
  const [contents, setContents] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dots, setDots] = useState("");

  useEffect(() => {
    let interval;
    if (isOpen && isLoading) {
      setDots(""); // Reset dots when the modal is opened
      interval = setInterval(() => {
        setDots((prev) => prev + "."); // Keep adding dots
      }, 750);
    }

    // Cleanup interval on close
    return () => clearInterval(interval);
  }, [isOpen, isLoading]);

  useEffect(() => {
    if (isOpen && work) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/literary_work_contents/${work.id}/`
        )
        .then((response) => {
          let fetchedContents = response.data.contents;
          setContents(fetchedContents);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching contents:", error);
          setIsLoading(false);
        });
    }
  }, [isOpen, work]);

  const workType = work?.category?.split("_")[1];

  console.log(workType);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={workType === "novel" ? "5xl" : "md"}
      isCentered
    >
      <ModalOverlay />

      {isLoading ? (
        <ModalContent>
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="400px"
          >
            <Text fontSize="xl">작품 불러오는 중.{dots}</Text>
          </ModalBody>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader
            px={4}
            py={3}
            borderBottom="1px solid #E2E8F0"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="bold">
              {work.author}, 「{work.title}」
            </Text>
            <Box display="flex" gap={2}>
              <Button
                size="sm"
                colorScheme="red"
                onClick={onClose}
                variant="outline"
              >
                닫기
              </Button>
            </Box>
          </ModalHeader>
          <ModalBody px={3} py={3} maxHeight="600px" overflowY="auto">
            <Box textAlign="left" whiteSpace="pre-wrap">
              <Text mb={0}>{contents.trim() || "작품 내용"}</Text>
              <Text textAlign="right" mt={0}></Text>
            </Box>
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
}
