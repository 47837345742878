import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Grid,
  Center,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  useToast,
  IconButton,
  HStack,
  Checkbox,
  useRadio,
  useRadioGroup,
  Card,
  CardBody,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  Input,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { AddIcon, EditIcon, CheckIcon } from "@chakra-ui/icons";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import axios from "axios";
import apiClient from "../../../api/axiosInstance"; // 생성한 Axios 인스턴스를 import
import { useNavigate, useLocation } from "react-router-dom";
import { whiten } from "@chakra-ui/theme-tools";
import {
  typeColors,
  pastelColors,
  typeData,
  typeDataNovel,
  typeDataPoem,
  technologyTypeData,
  scienceTypeData,
  societyTypeData,
  artTypeData,
  humanityTypeData,
  readingTypeData,
} from "./components/ProblemTypesAndExamples.js";

// RadioCard 컴포넌트 정의
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { colorScheme } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" mb={2}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="full"
        boxShadow="md"
        _checked={{
          bg: `${colorScheme}.600`,
          color: "white",
          borderColor: `${colorScheme}.600`,
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={3}
        py={2}
        bg={`${colorScheme}.100`}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function CreateQuestionDashboard() {
  const [isLoading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [generatedSolutions, setGeneratedSolutions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [includeSupplement, setIncludeSupplement] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [checkedBoxList, setCheckedBoxList] = useState([]);
  const [keywords, setKeywords] = useState([]); // 저장된 키워드
  const [keywordInput, setKeywordInput] = useState(""); // 키워드 입력 값
  const token = localStorage.getItem("token");
  const [paragraphs, setParagraphs] = useState([]);
  const [paragraphKeywords, setParagraphKeywords] = useState([]);
  const [paragraphKeywordInputs, setParagraphKeywordInputs] = useState([]);
  const [paragraphKeywordTypes, setParagraphKeywordTypes] = useState([]);

  // Modal 상태와 관련된 state
  const [typeDetails, setTypeDetails] = useState(null);

  // 카테고리에 따라 유형 데이터 선택
  const category = location.state.category || "";
  const isMoonhak = category.includes("문학 작품");
  let currentTypeData = ""; // 'let'으로 변경
  if (isMoonhak) {
    if (category === "문학 작품 - 현대 소설") {
      currentTypeData = typeDataNovel;
    } else if (category === "문학 작품 - 현대 시") {
      currentTypeData = typeDataPoem;
    }
  } else if (category.includes("과학")) {
    currentTypeData = scienceTypeData;
  } else if (category.includes("기술")) {
    currentTypeData = technologyTypeData;
  } else if (category.includes("사회")) {
    currentTypeData = societyTypeData;
  } else if (category.includes("예술")) {
    currentTypeData = artTypeData;
  } else if (category.includes("인문")) {
    currentTypeData = humanityTypeData;
  } else if (category.includes("독서")) {
    currentTypeData = readingTypeData;
  } else {
    currentTypeData = typeData;
  }

  // 문제 편집 상태 관리 변수
  const [editMode, setEditMode] = useState(Array(1).fill(false));

  // 사용자 입력 추적 위한 temp 변수
  const [tempInput, setTempInput] = useState("");

  const [rating, setRating] = useState(null); // 별점 상태
  const [comment, setComment] = useState(""); // 의견 상태
  const [sendFeedback, setSendFeedback] = useState(false); // Whether to send the rating and comment

  const [isShowPartSelectButton, setIsShowPartSelectButton] = useState(false);
  const [isSelectingPartOfPassage, SetIsSelectingPartOfPassage] = useState(
    false
  );
  const [existingQuestions, setExistingQuestions] = useState([]); // 조회된 기존 문제들
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [highlighterColor, SetHilighterColor] = useState("red");
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [selectionOffsets, setSelectionOffsets] = useState({});
  const [highlightRanges, setHighlightRanges] = useState([]);
  const passageId = location.state?.passage_id;

  useEffect(() => {
    const initialParagraphs = extractTextFromJson(
      location.state.contents
    ).split("\n\n");
    setParagraphs(initialParagraphs);
  }, []);

  useEffect(() => {
    console.log(selectedTexts);
  }, [selectedTexts]);

  useEffect(() => {
    // 페이지 로드 시 해당 Passage의 기존 문제들을 조회
    const fetchExistingQuestions = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(
          `/problem_generator/passage_questions/${passageId}/`
        );
        setExistingQuestions(response.data.questions);
      } catch (error) {
        console.error("Error fetching existing questions:", error);
        toast({
          title: "문제 조회 실패",
          description: "문제를 조회하는 도중 오류가 발생했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchExistingQuestions();
  }, [passageId, toast]);

  const addCard = () => {
    setIsModalOpen(true);
  };

  const deleteCard = (id) => {
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedType("");
    setTypeDetails(null);
    setIncludeSupplement(false); // 모달 창 닫을 때 보기 포함 여부 초기화
    setIsShowPartSelectButton(false);
  };

  const handleTypeSelect = (value) => {
    setSelectedType(value);
    const selectedDetail = currentTypeData.find((item) => item.type === value);
    setTypeDetails(selectedDetail);
    setIsShowPartSelectButton(true);
  };

  const handleCheckboxChange = (e) => {
    setIncludeSupplement(e.target.checked);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    // 전체 지문을 저장하기 위해 paragraphs를 하나의 텍스트로 합칩니다.
    const fullText = paragraphs.join("\n\n");

    try {
      await apiClient.put(
        `/problem_generator/passages/${location.state.passage_id}`,
        {
          contents: fullText,
        }
      );
      toast({
        title: "지문 저장 성공",
        description: "전체 지문이 성공적으로 저장되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating passage", error);
      toast({
        title: "지문 저장 실패",
        description: "지문 저장 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const extractTextFromJson = (jsonString) => {
    try {
      // ```json 및 ``` 구문 제거
      const cleanedJsonString = jsonString
        .replace(/^```json/, "")
        .replace(/```$/, "")
        .trim();

      // 문자열을 JSON 객체로 변환
      const jsonObject = JSON.parse(cleanedJsonString);
      return extractTextRecursively(jsonObject);
    } catch (error) {
      console.warn("Invalid JSON string:", error);
      return jsonString; // JSON 변환 실패 시 원본 문자열 반환
    }
  };

  // JSON 객체에서 재귀적으로 텍스트만 추출하는 함수
  const extractTextRecursively = (json) => {
    let text = "";
    if (typeof json === "string") {
      return json;
    } else if (typeof json === "object" && json !== null) {
      for (const key in json) {
        text += extractTextRecursively(json[key]) + "\n\n";
      }
    }
    return text.trim();
  };

  /**
   * 선택된 텍스트의 시작점과 끝점을 전체 텍스트 기준으로 가져오는 함수
   * @param {Selection} selection
   * @returns
   */
  const getSelectionOffsets = (selection) => {
    // 전체 텍스트 가져오기
    const anchorNode = selection.anchorNode;
    let parentNode = anchorNode.parentNode;
    if (parentNode.className === "highlighted") {
      //한번 더
      parentNode = parentNode.parentNode;
    }
    const innerText = parentNode.innerText;
    const childNodes = parentNode.childNodes;

    // 선택한 텍스트의 시작지점 찾기. 전체 텍스트 기준.
    const anchorNodeOffset = innerText.indexOf(anchorNode.data);
    let startOffsetFromStart = anchorNodeOffset + selection.anchorOffset;

    // 선택한 텍스트의 끝지점 찾기. 전체 텍스트 기준.
    const focusNode = selection.focusNode;
    const focusNodeOffset = innerText.indexOf(focusNode.data);
    let endOffsetFromStart = focusNodeOffset + selection.focusOffset;

    // 만약 선택한 텍스트가 역순으로 선택되었다면, 시작점과 끝점을 바꿔줍니다.
    if (startOffsetFromStart > endOffsetFromStart) {
      const temp = startOffsetFromStart;
      startOffsetFromStart = endOffsetFromStart;
      endOffsetFromStart = temp;
    }
    return { startOffset: startOffsetFromStart, endOffset: endOffsetFromStart };
  };

  const handleMouseUp = (event) => {
    setTimeout(() => {
      // '지문 중 일부 선택' 상태가 아니라면 종료.
      if (!isSelectingPartOfPassage) {
        return;
      }
      // ↓ Status: (지문 중 일부 선택 상태)

      const selection = window.getSelection();
      const selectedText = selection.toString();

      // 선택한 텍스트가 2글자 이상이 아니라면 팝업을 숨기기.
      if (selectedText.length < 2) {
        setShowPopup(false);
        return;
      }
      // ↓ Status: (지문 중 일부 선택) && (선택한 텍스트가 2글자 이상)

      //#region Validation Check
      // whether highlighter is possible.

      // 선택한 텍스트의 전체 텍스트 기준 시작 위치, 끝 위치 가져오기
      const { startOffset, endOffset } = getSelectionOffsets(selection);
      // 선택한 range가 중복으로 되진 않는지 확인
      const isOverlap = highlightRanges.some(
        (range) =>
          // 1. endOffset이 range 범위 안에 들어가면 중복.
          // 2. startOffset이 range 범위 안에 들어가면 중복.
          // 3. 드래그 한 부분이 range 범위를 포함하는지?
          (endOffset > range.start && endOffset < range.end) ||
          (startOffset > range.start && startOffset < range.end) ||
          (startOffset <= range.start && endOffset >= range.end)
      );

      // 중복되면 끝내기
      if (isOverlap) {
        toast({
          title: "중복된 범위",
          description: "이미 선택된 범위와 중복되는 부분이 있습니다.",
          status: "warning",
          duration: 1000,
          isClosable: true,
        });
        return;
      }
      //#endregion
      // ↓ Status: (범위 중복 없음)

      // 선택한 텍스트의 offset 들 저장
      setSelectionOffsets({ startOffset: startOffset, endOffset: endOffset });
      // 선택한 텍스트의 중간 위치값 계산
      const range = selection.getRangeAt(0);
      const selectedTextRect = range.getBoundingClientRect();
      // baseNode의 getBoundingClientRect 호출 (기준위치)
      const baseNode = range.startContainer.parentNode;
      const baseNodeRect = baseNode.getBoundingClientRect();
      const selectedTextMiddle =
        selectedTextRect.left - baseNodeRect.left + selectedTextRect.width / 2;
      const selectedTextTop =
        selectedTextRect.top + window.scrollY + selectedTextRect.height / 2;

      // 팝업 위치 설정 및 표시
      setPopupPosition({ x: selectedTextMiddle, y: selectedTextTop });
      setShowPopup(true);
    }, 0);
  };

  /**
   * 선택된 텍스트를 특정 색으로 감싸는 함수
   * @param {string} selectedText - 선택된 텍스트
   * @param {string} bgcolor - 배경색
   * @returns {HTMLSpanElement} - 색상이 적용된 SPAN 요소
   */
  const applyHighlight = (selectedText, bgcolor) => {
    const span = document.createElement("span");
    span.classList.add("highlighted"); // .highlighted 클래스를 추가하여 나중에 쉽게 구별
    span.style.backgroundColor = bgcolor; // 배경색 지정
    span.textContent = selectedText;
    return span;
  };

  /**
   * 하이라이트 버튼 클릭 시 실행되는 함수
   * 1. 하이라이트 시각화
   * 2. 시작점과 끝점 위치를 저장
   * 3. 드래그 텍스트 저장
   * 4. 팝업 닫기
   * @returns
   */
  const handleHighlightBtnClick = () => {
    // 1. 하이라이트 시각화
    // 선택된 텍스트의 범위를 가져옵니다.
    const selection = window.getSelection();
    let range = selection.getRangeAt(0);
    // 선택된 텍스트를 삭제하고 새로운 SPAN 요소 삽입
    const selectedText = range.toString();
    const newSpan = applyHighlight(
      selectedText,
      pastelColors[highlighterColor]
    );
    range.deleteContents();
    range.insertNode(newSpan);

    // 선택된 텍스트의 시작점과 끝점을 전체 텍스트 기준으로 가져오기
    const { startOffset, endOffset } = selectionOffsets;

    // 2. 시작점과 끝점 위치를 저장
    // higlightRanges에 새로운 범위 추가
    setHighlightRanges([
      ...highlightRanges,
      { start: startOffset, end: endOffset },
    ]);

    // 3. 문구가 뭔지 저장
    // 선택한 텍스트들에 추가
    setSelectedTexts([...selectedTexts, selectedText]);

    // 4. 팝업 닫기
    // 하이라이트 버튼 닫기, 선택된 텍스트 범위를 모두 해제
    setShowPopup(false);
    document.getSelection().removeAllRanges();

    return;
  };

  /**
   * 전체 글자 수 계산 함수
   */
  const getTotalCharacterCount = () => {
    return paragraphs.reduce((total, paragraph) => total + paragraph.length, 0);
  };

  const handleSelectPartOfPassage = (selectedType) => {
    SetIsSelectingPartOfPassage(true);
    SetHilighterColor(typeColors[selectedType]);
  };

  const addQuestion = () => {
    if (typeDetails) {
      setCards((prevCards) => [
        ...prevCards,
        {
          id: prevCards.length,
          type: selectedType,
          policy: typeDetails.policy,
          example: typeDetails.example,
          includeSupplement,
          selectedTexts: selectedTexts,
        },
      ]);
      setSelectedType("");
      setSelectedTexts([]);
      setTypeDetails(null);
      setIncludeSupplement(false); // 문제 추가 시 보기 포함 여부 초기화
      closeModal();
    } else {
      toast({
        title: "모든 필드를 입력해 주세요.",
        description: "문제 유형과 문제 본문을 입력해 주세요.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleParagraphKeywordInput = (index, value) => {
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = value;
    setParagraphKeywordInputs(newInputs);
  };

  const handleParagraphKeywordTypeChange = (index, value) => {
    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = value;
    setParagraphKeywordTypes(newTypes);
  };

  const handleParagraphChange = (index, newValue) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index] = newValue;
    setParagraphs(newParagraphs);
  };

  const handleAddParagraphKeyword = (index) => {
    const keyword = paragraphKeywordInputs[index].trim();
    const keywordType = paragraphKeywordTypes[index] || "정의"; // 기본값을 "정의"로 설정
    if (keyword === "") return;

    const newParagraphKeywords = [...paragraphKeywords];
    if (!newParagraphKeywords[index]) {
      newParagraphKeywords[index] = [];
    }

    // 키워드와 키워드 타입을 함께 저장
    newParagraphKeywords[index].push({ keyword, type: keywordType });
    setParagraphKeywords(newParagraphKeywords);

    // 입력 필드와 타입 초기화
    const newInputs = [...paragraphKeywordInputs];
    newInputs[index] = "";
    setParagraphKeywordInputs(newInputs);

    const newTypes = [...paragraphKeywordTypes];
    newTypes[index] = "정의";
    setParagraphKeywordTypes(newTypes);
  };

  const handleRemoveParagraphKeyword = (paragraphIndex, keyword) => {
    const newParagraphKeywords = [...paragraphKeywords];

    // 해당 문단에서 특정 키워드 객체를 제거
    newParagraphKeywords[paragraphIndex] = newParagraphKeywords[
      paragraphIndex
    ].filter(
      (item) => item.keyword !== keyword // 객체의 keyword 속성을 비교
    );

    setParagraphKeywords(newParagraphKeywords);
  };

  const handleRegenerateParagraph = (index) => {
    setLoading(true);
    apiClient
      .post(`/problem_generator/regenerate_paragraph/`, {
        paragraph: paragraphs[index],
        keywords: paragraphKeywords[index],
      })
      .then((response) => {
        const newParagraphs = [...paragraphs];
        newParagraphs[index] = response.data.new_paragraph;
        setParagraphs(newParagraphs);
      })
      .catch((error) => {
        toast({
          title: "문단 재생성 실패",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error regenerating paragraph:", error);
      })
      .finally(() => setLoading(false));
  };

  const createQuestion = () => {
    const selectedOptions = cards.map((card) => ({
      type: card.type,
      includeSupplement: card.includeSupplement, // 보기 포함 여부 추가
      example: card.example,
      policy: card.policy,
      selectedTexts: card.selectedTexts,
    }));
    const data = {
      passage_id: location.state.passage_id,
      passage_title: location.state.title,
      contents: location.state.contents,
      category: location.state.category,
      selected_options: selectedOptions,
    };
    setLoading(true);
    apiClient
      .post(`/problem_generator/generate/`, data)
      .then((response) => {
        setGeneratedSolutions(response.data.solutions);
        setGeneratedQuestions(response.data.questions);
        // 편집여부 상태변수 업데이트
        setEditMode(Array(response.data.questions.length).fill(false));
        toast({
          title: "문제가 성공적으로 생성되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "문제 생성에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGeneratedCheckboxChange = (e, index) => {
    if (e.target.checked) {
      setCheckedBoxList((prevList) => [...prevList, index]);
    } else {
      setCheckedBoxList((prevList) =>
        prevList.filter((item) => item !== index)
      );
    }
  };

  const saveSelectedQuestions = () => {
    if (editMode.includes(true)) {
      toast({
        title: "편집 중인 문제가 있습니다.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const selectedQuestions = checkedBoxList.map(
      (index) => generatedQuestions[index]
    );
    const selectedSolutions = checkedBoxList.map(
      (index) => generatedSolutions[index]
    );
    const data = {
      questions: selectedQuestions,
      solutions: selectedSolutions,
      passage_id: location.state.passage_id,
    };
    if (sendFeedback) {
      if (rating >= 0 && rating != null) {
        data.rating = rating;
      }

      if (comment.trim()) {
        data.feedback = comment; // Add feedback only if comment has content
      }
    }

    setLoading(true);

    apiClient
      .post(`/problem_generator/save_selected_questions/`, data)
      .then((response) => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // navigate("/problem-bank");
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useRadioGroup 훅 사용
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "questionType",
    value: selectedType,
    onChange: handleTypeSelect,
  });
  const group = getRootProps();

  // 편집 상태 변경 함수
  const enterEditMode = (index) => {
    const newEditMode = [...editMode];
    newEditMode[index] = true;
    setEditMode(newEditMode);
  };

  const exitEditMode = (index) => {
    const newEditMode = [...editMode];
    newEditMode[index] = false;
    setEditMode(newEditMode);
  };

  const updateQuestionProperty = (index, propertyName, value) => {
    let updatedQuestions = [...generatedQuestions];
    updatedQuestions[index][propertyName] = value;
    setGeneratedQuestions(updatedQuestions);
  };

  const updateSolutionPropery = (index, propertyName, value) => {
    let updatedSolutions = [...generatedSolutions];
    updatedSolutions[index][propertyName] = value;
    setGeneratedSolutions(updatedSolutions);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center mb={3}>
              <Heading size="md">{location.state.title}</Heading>
            </Center>
            <Flex justifyContent="space-between" alignItems="center">
              <Tag>{location.state.category}</Tag>
              {isEditing ? (
                <Button
                  borderRadius="full"
                  colorScheme="teal"
                  onClick={handleSaveClick}
                >
                  전체 지문 저장
                </Button>
              ) : (
                <Button
                  borderRadius="full"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  수정
                </Button>
              )}
            </Flex>
          </Box>
          <Box mb={4} p={4} bg="gray.50" borderRadius="lg" textAlign="center">
            <Text fontSize="sm">전체 글자 수</Text>
            <Text fontSize="2xl" fontWeight="bold" color="teal.600">
              {getTotalCharacterCount()}자
            </Text>
          </Box>
          {isEditing === false ? (
            <Text whiteSpace="pre-wrap" onClick={handleMouseUp}>
              {paragraphs.join("\n\n")}
              {showPopup && (
                <Box
                  position="absolute"
                  top={`${popupPosition.y}px`}
                  left={`${popupPosition.x}px`}
                  zIndex={10}
                >
                  <Button
                    size="sm"
                    colorScheme={highlighterColor}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHighlightBtnClick("Option 1");
                    }}
                  >
                    하이라이트
                  </Button>
                </Box>
              )}
            </Text>
          ) : (
            paragraphs.map((paragraph, index) => (
              <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="lg">
                <Text mb={2} fontWeight="bold">
                  문단 {index + 1}
                </Text>
                <Textarea
                  value={paragraph}
                  onChange={(e) => handleParagraphChange(index, e.target.value)}
                  mb={3}
                  minHeight="100px"
                />

                <Flex mb={2}>
                  <Input
                    placeholder="문단 키워드 입력"
                    borderRadius="full"
                    value={paragraphKeywordInputs[index]}
                    onChange={(e) =>
                      handleParagraphKeywordInput(index, e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // 기본 Enter 동작을 막기
                        handleAddParagraphKeyword(index);
                        handleParagraphKeywordInput(index, ""); // 입력 필드를 초기화
                      }
                    }}
                    mr={2}
                  />
                  <Select
                    placeholder="키워드 타입 선택"
                    value={paragraphKeywordTypes[index] || "정의"}
                    onChange={(e) =>
                      handleParagraphKeywordTypeChange(index, e.target.value)
                    }
                    width="30%"
                    mr={2}
                    borderRadius="full"
                  >
                    <option value="정의">정의</option>
                    <option value="예시">예시</option>
                    <option value="예시">상세 설명</option>
                  </Select>
                  <Button
                    colorScheme="teal"
                    onClick={() => handleAddParagraphKeyword(index)}
                    borderRadius="full"
                  >
                    추가
                  </Button>
                </Flex>

                <Wrap mb={3}>
                  {paragraphKeywords[index] &&
                    paragraphKeywords[index].map((item, kidx) => (
                      <WrapItem key={kidx}>
                        <Tag colorScheme="teal" borderRadius="full">
                          <TagLabel>
                            {item.keyword} ({item.type})
                          </TagLabel>
                          <TagCloseButton
                            onClick={() =>
                              handleRemoveParagraphKeyword(index, item.keyword)
                            }
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                </Wrap>
                <Button
                  colorScheme="teal"
                  onClick={() => handleRegenerateParagraph(index)}
                  isLoading={isLoading}
                  borderRadius="full"
                >
                  문단 재생성
                </Button>
              </Box>
            ))
          )}
        </Box>
        {isSelectingPartOfPassage ? (
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Heading p={2} size="md">선택중인 유형</Heading>
            <Box
              width="50%"
              bg={highlighterColor + ".100"}
              borderRadius="md"
              p={4}
              maxHeight="500px"
              overflowY="auto"
            >
              <Text fontWeight="bold">유형: {typeDetails.type}</Text>
              <Text mt={2} fontWeight="bold">
                설명:
              </Text>
              <Text>{typeDetails.policy}</Text>
              {/* <Text mt={2} fontWeight="bold">
                문제 예시:
              </Text>
              <Text whiteSpace="pre-wrap">{typeDetails.example}</Text> */}
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center>
              <Heading size="md">출제된 문제 목록</Heading>
            </Center>
          </Box>

          {/* 조회된 기존 문제들을 보여주는 컴포넌트 */}
          {/* Accordion for displaying questions */}
          {existingQuestions.length > 0 ? (
            <Accordion allowToggle>
              {existingQuestions.map((question, index) => (
                <AccordionItem
                  key={index}
                  borderWidth="1px"
                  borderRadius="lg"
                  mb={3}
                >
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        문제 {index + 1}: {question.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {question.supplement && (
                      <Box
                        bg="gray.100"
                        borderWidth="1px"
                        borderRadius="md"
                        p={3}
                        whiteSpace="pre-wrap"
                        mb={4}
                      >
                        {question.supplement}
                      </Box>
                    )}
                    <VStack spacing={2} alignItems="start">
                      <Text>1. {question.choice_1}</Text>
                      <Text>2. {question.choice_2}</Text>
                      <Text>3. {question.choice_3}</Text>
                      <Text>4. {question.choice_4}</Text>
                      <Text>5. {question.choice_5}</Text>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <Text>출제된 문제가 없습니다.</Text>
          )}
        </Box>
        {isSelectingPartOfPassage ? (
          <Center mt={3}>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                addQuestion();
                SetIsSelectingPartOfPassage(false);
              }}
              isLoading={isLoading}
              mr={3}
              borderRadius="full"
            >
              선택 완료
            </Button>
          </Center>
        ) : (
          <Center mt={3}>
            <Button
              colorScheme="teal"
              onClick={addCard}
              isLoading={isLoading}
              mr={3}
              borderRadius="full"
            >
              문제 출제 유형 선택
            </Button>
          </Center>
        )}
      </Grid>

      {/* Modal for adding a new question */}
      <Modal
        isOpen={isModalOpen && !isSelectingPartOfPassage}
        onClose={closeModal}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>문제 유형 및 보기 설정</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              {/* 왼쪽: 문제 유형 선택 */}
              <Box width="50%" pr={4} maxHeight="500px" overflowY="scroll">
                <Wrap spacing={4} {...group}>
                  {currentTypeData.map((type) => {
                    const radio = getRadioProps({ value: type.type });
                    const colorScheme = typeColors[type.type] || "gray";
                    return (
                      <WrapItem key={type.type}>
                        <RadioCard {...radio} colorScheme={colorScheme}>
                          <Text fontWeight="bold">{type.type}</Text>
                        </RadioCard>
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </Box>

              {/* 오른쪽: 선택된 문제 유형에 대한 설명 */}
              <Box width="50%" pl={4}>
                {typeDetails ? (
                  <Box
                    width="100%"
                    bg="gray.100"
                    borderRadius="md"
                    p={4}
                    maxHeight="500px"
                    overflowY="auto"
                  >
                    <Text fontWeight="bold">유형: {typeDetails.type}</Text>
                    <Text mt={2} fontWeight="bold">
                      설명:
                    </Text>
                    <Text>{typeDetails.policy}</Text>
                    <Text mt={2} fontWeight="bold">
                      문제 예시:
                    </Text>
                    <Text whiteSpace="pre-wrap">{typeDetails.example}</Text>
                  </Box>
                ) : (
                  <Box width="100%" bg="gray.100" borderRadius="md" p={4}>
                    <Text>
                      문제 유형을 선택하면 자세한 설명이 여기에 표시됩니다.
                    </Text>
                  </Box>
                )}
                <Flex direction="column" align="start">
                  {/* 지문의 내용 일부 선택하는 버튼 */}
                  {isShowPartSelectButton && (
                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        setShowPopup(false);
                        SetIsSelectingPartOfPassage(true);
                        SetHilighterColor(typeColors[selectedType]);
                      }}
                      mt={4}
                      borderRadius="full"
                    >
                      지문 중 참조하기
                    </Button>
                  )}
                  {/* 보기 포함 여부 선택 */}
                  <Checkbox
                    mt={4}
                    isChecked={includeSupplement}
                    onChange={handleCheckboxChange}
                  >
                    보기 포함 여부
                  </Checkbox>
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={addQuestion}
              isLoading={isLoading}
              borderRadius="full"
            >
              문제 추가
            </Button>
            <Button
              variant="ghost"
              onClick={closeModal}
              ml={3}
              borderRadius="full"
            >
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Display added cards */}
      {cards.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Center mb={3}>
              <Heading size="md">출제 문제 유형 예시</Heading>
            </Center>
            <VStack spacing={4}>
              {cards.map((card, index) => (
                <Box
                  key={index}
                  bg="gray.50"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  width="100%"
                >
                  <Text fontWeight="bold" mb={2}>
                    문제 유형: {card.type}
                  </Text>
                  <Text fontWeight="bold" mb={2}>
                    유형 설명:
                  </Text>
                  <Text mb={2}>{card.policy}</Text>
                  <Text fontWeight="bold" mb={2}>
                    문제 예시:
                  </Text>
                  <Text mb={2} whiteSpace="pre-wrap">
                    {card.example}
                  </Text>
                  <Text fontWeight="bold" mb={2}>
                    보기 포함 여부: {card.includeSupplement ? "예" : "아니오"}
                  </Text>

                  {card.selectedTexts.length > 0 &&
                    card.selectedTexts.map((text, idx) => (
                      <Text key={idx} mb={2}>
                        참조 {idx + 1}: {text}
                      </Text>
                    ))}

                  <IconButton
                    aria-label="Delete"
                    icon={<MdOutlineDelete />}
                    colorScheme="red"
                    onClick={() => deleteCard(card.id)}
                    size="sm"
                    variant="outline"
                    alignSelf="flex-end"
                  />
                </Box>
              ))}
            </VStack>
            <Center mt={3}>
              <Button
                colorScheme="teal"
                onClick={createQuestion}
                isLoading={isLoading}
                borderRadius="full"
              >
                문제 생성
              </Button>
            </Center>
          </Box>
        </Grid>
      )}

      {/* display generated questions */}
      {generatedQuestions.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Box p={3}>
              <Center>
                <Heading size="md">생성된 문제 및 해설</Heading>
              </Center>
            </Box>
            {generatedQuestions.map((question, index) => {
              // 편집상태이면 편집모드로 렌더링
              // 아니면 일반모드로 렌더링
              return editMode[index] ? (
                <Box
                  key={index}
                  bg="gray.200"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={3}
                  mb={4}
                >
                  <Checkbox
                    colorScheme="teal"
                    size="lg"
                    mb={2}
                    onChange={(e) => handleGeneratedCheckboxChange(e, index)}
                  />
                  <Heading size="sm">
                    문제 {index + 1}
                    <Input
                      defaultValue={question.question}
                      backgroundColor="white"
                      onBlur={(e) => {
                        updateQuestionProperty(
                          index,
                          "question",
                          e.target.value
                        );
                      }}
                    />
                  </Heading>
                  <Text width="100%">
                    보기
                    <Textarea
                      bg="white"
                      p={3}
                      mt={3}
                      defaultValue={question.supplement}
                      onBlur={(e) => {
                        updateQuestionProperty(
                          index,
                          "supplement",
                          e.target.value
                        );
                      }}
                    />
                  </Text>
                  <VStack spacing={2} mt={2} alignItems="start">
                    <Text width="100%">
                      1.
                      <Input
                        defaultValue={question.choice_1}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_1",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      2.
                      <Input
                        defaultValue={question.choice_2}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_2",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      3.
                      <Input
                        defaultValue={question.choice_3}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_3",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      4.
                      <Input
                        defaultValue={question.choice_4}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_4",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text width="100%">
                      5.
                      <Input
                        defaultValue={question.choice_5}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateQuestionProperty(
                            index,
                            "choice_5",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text fontWeight="bold">
                      정답:
                      <Input
                        defaultValue={generatedSolutions[index].answer}
                        backgroundColor="white"
                        onBlur={(e) => {
                          updateSolutionPropery(
                            index,
                            "answer",
                            e.target.value
                          );
                        }}
                      />
                    </Text>
                    <Text mt={2} fontWeight="bold">
                      해설:
                    </Text>
                    <Input
                      defaultValue={generatedSolutions[index].explanation}
                      backgroundColor="white"
                      onBlur={(e) => {
                        updateSolutionPropery(
                          index,
                          "explanation",
                          e.target.value
                        );
                      }}
                    />
                  </VStack>
                  <IconButton
                    aria-label="Check"
                    onClick={() => {
                      exitEditMode(index);
                      // saveEditedQuestion(index);
                    }}
                    icon={<CheckIcon />}
                    size="sm"
                    alignSelf="flex-start"
                    marginTop="10px"
                  />
                </Box>
              ) : (
                <Box
                  key={index}
                  bg="gray.50"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={3}
                  mb={4}
                >
                  <Checkbox
                    colorScheme="teal"
                    size="lg"
                    mb={2}
                    onChange={(e) => handleGeneratedCheckboxChange(e, index)}
                  />
                  <Heading size="sm">
                    문제 {index + 1}: {question.question}
                  </Heading>
                  {question.supplement ? (
                    <Box
                      bg="white"
                      borderWidth="1px"
                      borderRadius="lg"
                      p={3}
                      whiteSpace="pre-wrap"
                      mt={3}
                    >
                      {question.supplement}
                    </Box>
                  ) : (
                    <></>
                  )}
                  <VStack spacing={2} mt={2} alignItems="start">
                    <Text>1. {question.choice_1}</Text>
                    <Text>2. {question.choice_2}</Text>
                    <Text>3. {question.choice_3}</Text>
                    <Text>4. {question.choice_4}</Text>
                    <Text>5. {question.choice_5}</Text>
                    <Text fontWeight="bold">
                      정답: {generatedSolutions[index].answer}
                    </Text>
                    <Text mt={2} fontWeight="bold">
                      해설:
                    </Text>
                    <Text>{generatedSolutions[index].explanation}</Text>
                  </VStack>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => enterEditMode(index)}
                    icon={<EditIcon />}
                    size="sm"
                    alignSelf="flex-start"
                    marginTop="10px"
                  />
                </Box>
              );
            })}
            <Box
              bg="white"
              borderWidth="1px"
              borderRadius="xl"
              p={4}
              mt={0}
              width={{ base: "100%", md: "100%" }}
            >
              <Text fontWeight="bold">별점 (5점 만점):</Text>
              <HStack>
                {[1, 2, 3, 4, 5].map((star) => (
                  <Box
                    key={star}
                    position="relative"
                    display="inline-block"
                    onMouseDown={(e) => {
                      const box = e.target.getBoundingClientRect();
                      const clickX = e.clientX - box.left;
                      const starWidth = box.width;

                      // Determine if click was on left or right side of the star
                      if (clickX < starWidth / 2) {
                        setRating(star - 0.5); // Half star
                      } else {
                        setRating(star); // Full star
                      }
                    }}
                  >
                    <IconButton
                      icon={
                        rating >= star ? (
                          <FaStar />
                        ) : rating >= star - 0.5 ? (
                          <FaStarHalfAlt />
                        ) : (
                          <FaRegStar />
                        )
                      }
                      variant="ghost"
                      aria-label={`Rate ${star}`}
                    />
                  </Box>
                ))}
              </HStack>

              <Text fontWeight="bold" mt={2} mb={1}>
                추가 의견:
              </Text>
              <Textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="의견을 입력해주세요."
                bg="white"
                borderRadius="xl"
              />

              <Checkbox
                isChecked={sendFeedback}
                onChange={(e) => setSendFeedback(e.target.checked)}
                colorScheme="teal"
                mt={2}
              >
                별점과 의견을 지문과 함께 저장합니다.
              </Checkbox>
            </Box>

            <Center>
              <Button
                colorScheme="teal"
                isLoading={isLoading}
                isDisabled={checkedBoxList.length == 0}
                onClick={saveSelectedQuestions}
                borderRadius="full"
              >
                선택된 문제 저장
              </Button>
            </Center>
          </Box>
        </Grid>
      )}
    </Flex>
  );
}
